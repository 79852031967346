export const INITIAL_VALUE = `
  <h1 style="text-align: center;">Shartnoma № {contract_number}</h1>
  <p style="text-align: center;">{date}</p>
  <h3>{status}</h3>
  <p>Tashkilot: {organization.name}</p>
  <p>Sotuvchi: {user_full_name}</p>
  <p>Yaratilgan sana: {created_at}</p>
  <p>Mijoz: {customer.name}</p>
  <p>Balance: {customer_before_balance}</p>
  <p>Qoldiq: {customer_after_balance}</p>
  <h2>Tovarlar</h2>
  <table style="width: 100%;">
      <thead>
          <tr>
              <th style="width: 70px;"><strong>№</strong></th>
              <th style="width: 15.7269%;"><strong>Nomi</strong></th>
              <th style="width: 16.7112%;"><strong>O&#39;lchov birligi</strong></th>
              <th style="width: 14.7344%;"><strong>Miqdori</strong></th>
              <th style="width: 14.2098%;"><strong>Qaytarildi</strong></th>
              <th style="width: 11.5538%; text-align: right;"><strong>Narxi</strong></th>
              <th style="width: 19.8223%; text-align: right;"><strong>Jami</strong></th>
          </tr>
      </thead>
      <tbody>
          <tr>
              <td style="width: 70px;">{index}</td>
              <td style="width: 15.7269%;">{product_name}</td>
              <td style="width: 16.7112%;">{sold_by}</td>
              <td style="width: 14.7344%;">{amount}</td>
              <td style="width: 14.2098%;">{refund_count}</td>
              <td style="width: 11.5538%; text-align: right;">{price}</td>
              <td style="width: 19.8223%; text-align: right;">{total}</td>
          </tr>
      </tbody>
  </table>
  <ul>
      <li style="text-align: right;"><strong>Jami:</strong> <em>{total_price}</em></li>
      <li style="text-align: right;"><strong>Umumiy xarid summasi:</strong> <em>{total_price}</em></li>
      <li style="text-align: right;"><strong>Yetkazib berish:</strong><em>{delivery_price}</em></li>
      <li style="text-align: right;"><strong>Jami chegirma:</strong><em>{total_discount}</em></li>
      <li style="text-align: right;"><strong>Jami qaytarilgan summa:</strong> <em>{total_refund}</em></li>
      <li style="text-align: right;"><strong>Qoldiq summa:</strong> <em>{resident_amount}</em></li>
      <li style="text-align: right;"><strong>Umumiy xarid summasi:</strong><em>&nbsp;{total_purchase}</em></li>
  </ul>`;

export const SHIPPING_LETTER_INITIAL_VALUE = `<p style="text-align: center">
      <strong>YUK XATI №</strong> <em>_________</em>
    </p>
    <p style="text-align: left">
      Kimdan
      <em
        >__________________________________________________________________________</em
      >
    </p>
    <p style="text-align: left">
      Kimga
      <em
        >___________________________________________________________________________</em
      >
    </p>
    <p style="text-align: left">
      Dov/dov
      <em
        >_________________________________________________________________________</em
      >
    </p>
    <p style="text-align: left"><br /></p>
    <table style="width: 100%">
      <thead>
        <tr>
          <th style="width: 5.5321%"><strong>№</strong></th>
          <th style="width: 12.1894%"><strong>Nomi</strong></th>
          <th style="width: 12.9395%"><strong>O&#39;lchov birligi</strong></th>
          <th style="width: 11.4393%"><strong>Miqdori</strong></th>
          <th style="width: 11.0642%; text-align: right;"><strong id="isPasted">Narxi</strong></th>
          <th style="width: 11.0993%; text-align: right;"><strong id="isPasted">Jami</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 5.5321%">{index}</td>
          <td style="width: 12.1894%">{product_name}</td>
          <td style="width: 12.9395%">{sold_by}</td>
          <td style="width: 11.4393%">{amount}</td>
          <td style="width: 11.0642%; text-align: right;">{price}</td>
          <td style="width: 11.0993%; text-align: right;">{total}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td><br /></td>
          <td><br /></td>
          <td><br /></td>
          <td><br /></td>
          <td><br /></td>
          <td style="text-align: right;">{total}</td>
        </tr>
      </tfoot>
    </table>
    <p style="text-align: center">
      Berdim <em>____________________________</em>&nbsp; &nbsp; &nbsp; &nbsp;
      Oldim <em>_____________________________</em>
  </p>`;

export const STATES = {
  created: "COMMON.STATUSES.created",
  pending: "COMMON.STATUSES.pending",
  accepted: "COMMON.STATUSES.accepted",
  in_progress: "COMMON.STATUSES.in_progress",
  done: "COMMON.STATUSES.done",
  rejected: "COMMON.STATUSES.rejected",
  canceled: "COMMON.STATUSES.canceled",
  in_transit: "COMMON.STATUSES.in_transit",
};

export const INITIAL_DATA = {
  apply_in_main: true,
  balance_list: [
    { currency_id: "611fc6ab4deb358a272df611", amount: 252065750 },
    { currency_id: "6141e75752f8032f780ce736", amount: 2000 },
    { currency_id: "61540991bc6517c9adb9c842", amount: 100 },
    { currency_id: "611fc6974deb358a272df5d2", amount: 350 },
  ],
  completely_deleted: false,
  contract_date: "2022-05-24T07:22:27.094Z",
  contract_description: "",
  contract_is_deleted: false,
  contract_number: 3629,
  contract_state: "in_progress",
  created_at: "2022-05-24T07:28:16.406Z",
  created_by: "60ee868594cd055dc77f0e24",
  cross_sell_products: [],
  customer: {
    _id: "61ea8111c6d3d878a3c47c91",
    name: '"REGISTON PRINT GROUdewedwedwedP" MAS\'ULIYATI CHEKLANGAN JAMIYATI',
  },
  customer_after_balance: [],
  customer_before_balance: [],
  customer_id: "61ea8111c6d3d878a3c47c91",
  delivery_price: 0,
  delivery_type: "take_away",
  is_booked: false,
  is_deleted: false,
  main_currency_id: "611fc6ab4deb358a272df611",
  main_currency_name: "UZS",
  main_currency_value: 11000,
  organization: { _id: "6141df6552f8032f780ce3d6", name: "Yunusobod filiali" },
  organization_id: "6141df6552f8032f780ce3d6",
  paid: 0,
  payed_balance_list: [],
  products: [
    {
      amount: 1,
      completely_deleted: false,
      cost: 15007.246376811594,
      cost_currency_id: "611fc6ab4deb358a272df611",
      cost_currency_value: 1,
      created_at: "2022-05-24T07:28:16.433Z",
      discount: 0,
      is_compound: false,
      is_deleted: false,
      is_discount: false,
      is_refund: false,
      is_trash: false,
      materials: [],
      organization_id: "6141df6552f8032f780ce3d6",
      price: 20000,
      price_currency_id: "611fc6ab4deb358a272df611",
      price_currency_uuid: 2,
      price_currency_value: 1,
      product_id: "625867ffd927379e0661164d",
      product_name: "1 tovarlar",
      product_uuid: null,
      real_price: 20000,
      refund_count: 0,
      report_saved: false,
      sale_id: "628c891029cc6adec2be2004",
      sold_by: "each",
      tax: 0,
      total_discount: 0,
      updated_at: "2022-05-24T07:28:16.433Z",
      _id: "628c891029cc6adec2be2013",
    },
    {
      amount: 2,
      completely_deleted: false,
      cost: 120000,
      cost_currency_id: "611fc6ab4deb358a272df611",
      cost_currency_value: 1,
      created_at: "2022-05-24T07:28:16.448Z",
      discount: 0,
      is_compound: false,
      is_deleted: false,
      is_discount: false,
      is_refund: false,
      is_trash: false,
      materials: [],
      organization_id: "6141df6552f8032f780ce3d6",
      price: 25000,
      price_currency_id: "611fc6ab4deb358a272df611",
      price_currency_uuid: 2,
      price_currency_value: 1,
      product_id: "62586819d927379e06611695",
      product_name: "2 tovar",
      product_uuid: null,
      real_price: 25000,
      refund_count: 0,
      report_saved: false,
      sale_id: "628c891029cc6adec2be2004",
      sold_by: "each",
      tax: 0,
      total_discount: 0,
      updated_at: "2022-05-24T07:28:16.448Z",
      _id: "628c891029cc6adec2be201d",
    },
    {
      amount: 3,
      category_id: "6156da964e89dc8dd62add54",
      completely_deleted: false,
      cost: 5000,
      cost_currency_id: "611fc6ab4deb358a272df611",
      cost_currency_value: 1,
      created_at: "2022-05-24T07:28:16.464Z",
      discount: 0,
      is_compound: false,
      is_deleted: false,
      is_discount: false,
      is_refund: false,
      is_trash: false,
      materials: [],
      organization_id: "6141df6552f8032f780ce3d6",
      price: 8000,
      price_currency_id: "611fc6ab4deb358a272df611",
      price_currency_uuid: 2,
      price_currency_value: 1,
      product_id: "623d4a4b427242ae99fa16a7",
      product_name: "25.03.2022",
      product_uuid: null,
      real_price: 8000,
      refund_count: 0,
      report_saved: false,
      sale_id: "628c891029cc6adec2be2004",
      sold_by: "each",
      tax: 0,
      total_discount: 0,
      updated_at: "2022-05-24T07:28:16.464Z",
      _id: "628c891029cc6adec2be2027",
    },
    {
      amount: 4,
      completely_deleted: false,
      cost: 2012140.7249466951,
      cost_currency_id: "611fc6ab4deb358a272df611",
      cost_currency_value: 1,
      created_at: "2022-05-24T07:28:16.481Z",
      discount: 0,
      is_compound: false,
      is_deleted: false,
      is_discount: false,
      is_refund: false,
      is_trash: false,
      materials: [],
      organization_id: "6141df6552f8032f780ce3d6",
      price: 35000,
      price_currency_id: "611fc6ab4deb358a272df611",
      price_currency_uuid: 2,
      price_currency_value: 1,
      product_id: "62586875d927379e0661176f",
      product_name: "3 tovarlar",
      product_uuid: null,
      real_price: 35000,
      refund_count: 0,
      report_saved: false,
      sale_id: "628c891029cc6adec2be2004",
      sold_by: "each",
      tax: 0,
      total_discount: 0,
      updated_at: "2022-05-24T07:28:16.481Z",
      _id: "628c891029cc6adec2be2031",
    },
  ],
  refund_products: [],
  sale_type: "straight",
  status: "done",
  total_cost: 1473343359516.0364,
  total_discount: 0,
  total_price: 255915750,
  total_purchase: 255915750,
  total_refund: 0,
  total_tax: 0,
  updated_at: "2022-05-25T07:56:24.736Z",
  user_full_name: "Ha-ha ?",
  _id: "628c891029cc6adec2be2004",
};
