import { styled } from "@mui/system";
import { Autocomplete } from "@mui/material";

export const StyledSelect = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 12,
  },
  "& .MuiOutlinedInput-input": {
    padding: "0!important",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 9px)",
    color: "#313649",
  },
  "& .MuiChip-root": {
    height: "25px",
    fontSize: "14px",
    margin: "1.5px",
    "& svg": {
      fontSize: "18px",
    },
  },
  "&.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EA5455",
  },
  "& .MuiOutlinedInput-root .MuiButtonBase-root path": {
    fill: "#313649",
  },
  "&.error .MuiOutlinedInput-root .MuiButtonBase-root path": {
    fill: "#EA5455",
  },
});
