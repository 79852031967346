import { IndexedDB, isExistDb } from "utils";
import { LOCAL_STORAGE_CONST } from "constants/localStorage.constants";
import get from "lodash.get";
import PosDB from "pages/pos/utils/PosDB/PosDB";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";
import { posUniversal } from "services/api";

export const useReceipt = () => {
  const upsertReceipt = async (notificationData) => {
    const isExistTheNamedDb = await isExistDb(
      notificationData?.organization_id
    );
    const editReceipt = async (organization_id) => {
      const DB = new PosDB(organization_id);
      const organizationDataInLocalStorage = JSON.parse(
        localStorage.getItem(
          `${LOCAL_STORAGE_CONST.organization}_${organization_id}`
        )
      );
      const {
        data: { total },
      } = await posUniversal.client("/back-api/admin/pos/receipt/get-paging", {
        limit: 1,
        page: 1,
        organization_id,
        updated_at: get(
          organizationDataInLocalStorage,
          LOCAL_STORAGE_CONST.receiptUpdatedAt
        ),
      });
      const {
        data: { data },
        time,
      } =
        total &&
        (await posUniversal.client("/back-api/admin/pos/receipt/get-paging", {
          limit: total + 1,
          page: 1,
          organization_id,
          updated_at: get(
            organizationDataInLocalStorage,
            LOCAL_STORAGE_CONST.receiptUpdatedAt
          ),
        }));
      if (data?.length) {
        localStorage.setItem(
          `${LOCAL_STORAGE_CONST.organization}_${organization_id}`,
          JSON.stringify({
            ...organizationDataInLocalStorage,
            [LOCAL_STORAGE_CONST.receiptUpdatedAt]: time,
          })
        );
        DB.connect().then((apis) => {
          apis.editReceipts(data);
        });
      }
    };
    if (!notificationData?.organization_id) {
      const commonDb = new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      });
      await commonDb.connect();
      const organizationsInSessionDb = await commonDb
        .getCollection("Session")
        .getById("organizations");
      organizationsInSessionDb?.data?.map((organization_id) =>
        editReceipt(organization_id)
      );
    }
    if (isExistTheNamedDb) {
      editReceipt(notificationData?.organization_id);
    }
  };

  return { upsertReceipt };
};
