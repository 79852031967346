import {toast} from "react-hot-toast"
import {create_warehouse_income} from "../../../store/warehouse/income/create";
import {Success} from "../../../api";



const create_WareHouse_Income_Reducer = (
    state = create_warehouse_income,
    action
) => {
    switch (action.type) {
        case "CREATE_WAREHOUSE_INCOME_0":
            return Success(
                "/warehouse/income",
                "created successfully!!!",
                action.payload.history
            );
        case "CREATE_WAREHOUSE_INCOME_35000":
            return toast.error(action.payload);
        case "CREATE_WAREHOUSE_INCOME_10001":
            return toast.error(action.payload);
        case "CREATE_WAREHOUSE_INCOME_10000":
            return toast.error(action.payload);
        default:
            return state;
    }
};
export default create_WareHouse_Income_Reducer;
