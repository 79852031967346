import { FormControl, InputBase, InputLabel, Select } from "@mui/material";
import { styled } from "@mui/system";

export const StyledFormControl = styled(FormControl)({
  marginTop: "30px",
});

export const StyledInputLabel = styled(InputLabel)({
  marginTop: "-12px",
});

export const StyledBootstrapInput = styled(InputBase)({
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #ced4da",
  fontSize: 16,
  padding: "10px 26px 10px 12px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
  ],
});

export const StyledSelect = styled(Select)({
  padding: "0",
  borderRadius: "12px",
  "& > div": {
    padding: "10px 32px 10px 10px",
    borderRadius: "12px !important",
  }
})