import {toast} from "react-hot-toast"
import {Success} from "../../api";
import {delete_task} from "../../store/tasks/delete";




const delete_Task_Reducer = (state = delete_task, action) => {
    switch (action.type) {
        case "DELETE_TASK_0" :
            return Success("/task/main", "deleted successfully!!!");
        case "DELETE_TASK_52000":
            return toast.error(action.payload);
        case "DELETE_TASK_10000":
            return toast.error(action.payload);
        case "DELETE_TASK_10001":
            return toast.error(action.payload);
        default:
            return state
    }
}
export default delete_Task_Reducer