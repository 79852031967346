import { IndexedDB } from "utils";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";
import { useRequest } from "hooks";
import { LOCAL_STORAGE_CONST } from "constants/localStorage.constants";
import { posUniversal } from "services/api";

export const useHr = () => {
  const commonDB = new IndexedDB(COMMON_DB, {
    collections: COMMON_DB_COLLECTIONS,
  });

  const upsertAccess = async (notificationData) => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONST.userData));
    const setDataToDb = async () => {
      await commonDB.connect();
      const data = await posUniversal.client(
        "/back-api/admin/hr/access/get",
        {}
      );
      commonDB.getCollection("Accesses").edit({
        section: "hr",
        value: data,
      });
    };
    if (notificationData === "initial") {
      setDataToDb();
    } else if (user?.id === notificationData.data?._id) {
      setDataToDb();
    }
  };

  return { upsertAccess };
};
