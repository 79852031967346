import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

export const StyledWindowLoader = styled("div")({
    position: "absolute",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    zIndex: "9999",
    bottom: "0",
    width: "100%",
    backdropFilter: "blur(3px)"
})

export const StyledCircularProgress = styled(CircularProgress)({
    margin: "auto"
})