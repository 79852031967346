import { IndexedDB } from "utils";
import { POS_DB_COLLECTIONS } from "./PosDB.constants";
import {
  generateAdditionalApis,
  generateDBCollections,
  generateRestApis,
} from "./PosDB.utils";

class PosDB {
  #organizationId = "";
  #apis = null;
  #db = null;

  constructor(organizationId) {
    this.#organizationId = organizationId;
    this.#db = new IndexedDB(organizationId, {
      collections: generateDBCollections(POS_DB_COLLECTIONS),
    });
  }

  async connect() {
    if (this.#apis) {
      return this.#apis;
    }
    await this.#db.connect();
    const apis = this.#generateApis();
    this.#apis = apis;
    return apis;
  }

  #generateApis() {
    return POS_DB_COLLECTIONS.reduce((apis, collection) => {
      const collectionApis = this.#db.getCollection(collection.name.plural);
      const hasAdditionalApis = collection.deleters || collection.adders;

      return {
        ...apis,
        ...generateRestApis(collectionApis, collection.name),
        ...(hasAdditionalApis &&
          generateAdditionalApis(
            collectionApis,
            this.#organizationId,
            collection
          )),
      };
    }, {});
  }
}

export default PosDB;
