import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDA21tXl-KsiUyCxg6YUA_yW_TDYOVqk9M",
  authDomain: "unical-erp.firebaseapp.com",
  projectId: "unical-erp",
  storageBucket: "unical-erp.appspot.com",
  messagingSenderId: "526362764376",
  appId: "1:526362764376:web:63f9034f6bd0dd3231562d",
  measurementId: "G-7GP3SFWG43",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const publicKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
// firebase.messaging.subscribeToTopic()

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {}

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
