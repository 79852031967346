import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";

import { StyledTableContainer } from "./Table.style";
import TableRow from "./components/TableRow";
import TableSkeleton from "../Skeletons/TableSkeleton/TableSkeleton";

const Table = ({
  columns = [],
  data = [],
  renderItemProps,
  onRowClick = () => {},
  emptydatamessage,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const renderHeader = useMemo(
    () => (
      <TableHead>
        <MuiTableRow>
          {columns.map((column, index) => (
            <TableCell sx={column?.style} key={index}>
              {column.translationKey ? t(column.translationKey) : column.header}
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
    ),
    [columns]
  );

  const renderBody = () => {
    if (data.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              {emptydatamessage
                ? emptydatamessage
                : t("table.emptyDataSourceMessage")}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {data.map((item) => (
          <TableRow onClick={() => onRowClick(item)} key={item._id}>
            {columns.map((column, cellIndex) => (
              <TableCell sx={column?.style} key={cellIndex * 10}>
                {renderRowItem(column, item, cellIndex)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const renderRowItem = (column, dataItem, index) =>
    column.renderComponent
      ? column.renderComponent(dataItem, renderItemProps, index, t)
      : dataItem[column.dataKey];

  return (
    <TableContainer
      sx={{ overflowX: "scroll!important" }}
      component={StyledTableContainer}
    >
      {!data.length && id ? (
        <TableSkeleton columns={columns} />
      ) : (
        <MuiTable>
          {renderHeader}
          {renderBody()}
        </MuiTable>
      )}
    </TableContainer>
  );
};

export default Table;
