import { styled } from "@mui/system";
import { StyledFormField } from "../style/Form.style";

export const StyledSelect = styled(StyledFormField)({
  paddingLeft: 15,
  display: "flex",
  alignItems: "center",
  "& .MuiSelect-select:after": {
    display: "none",
  },
  "& .MuiSelect-select:focus": {
    borderRadius: "12px",
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input":
    {
      paddingRight: "32px !important",
    },
});

export const StyledSearchInputBlock = styled("div")({
  width: "100%",
  padding: "8px",
});
