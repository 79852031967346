export const COMMON_DB = "COMMON_DB";

export const COMMON_DB_COLLECTIONS = [
  { name: "Organizations", keyPath: "_id" },
  { name: "Accesses", keyPath: "section" },
  { name: "Currencies", keyPath: "_id" },
  { name: "PaymentMethods", keyPath: "_id" },
  { name: "SaleSettings", keyPath: "_id" },
  { name: "Session", keyPath: "_id" },
];
