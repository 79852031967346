import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

import { REQUEST_STATUSES, useRequest } from "hooks";
import { LOCAL_STORAGE_CONST } from "constants/localStorage.constants";
import { IndexedDB } from "utils";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";

const ChoseDeviceDialog = ({
  isOpenChoseDevice,
  setIsOpenChoseDevice,
  getDeviceFromDb,
}) => {
  const { t } = useTranslation();
  const [getDevicesClient, getDeviceData, getDeviceStatus] = useRequest();
  const [reservationClient] = useRequest();
  const [selectedDevice, setSelectedDevice] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [devicePagingData, setDevicePagingData] = useState({
    data: [],
    total: 0,
  });

  const commonDB = useMemo(
    () =>
      new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      }),
    []
  );

  const getDevices = async () => {
    setLoading(true);
    await getDevicesClient.post(
      "/pos-api/employee/settings/device/get-paging",
      {
        page,
        limit: 10,
        imei: localStorage.getItem(LOCAL_STORAGE_CONST.imei),
      },
      {
        username:
          window.location.host.split(".").length === 3
            ? window.location.host.split(".")[0]
            : "",
        device_name: "",
      }
    );
    setLoading(false);
  };

  const getDeviceToDetectIsSelectedFromDb = async () => {
    await commonDB.connect();
    const device = await commonDB.getCollection("Session").getById("device");
    setSelectedDevice(device?.device_id);
  };

  const reservationDevice = async () => {
    const findSelectedDevice = devicePagingData?.data?.find(
      (device) => device?._id === selectedDevice
    );
    const imei = localStorage.getItem(LOCAL_STORAGE_CONST.imei);
    const data = await reservationClient.post(
      "/pos-api/employee/settings/device/brone",
      {
        _id: findSelectedDevice?._id,
        device_name: findSelectedDevice?.name,
        imei,
      }
    );
    if (data) {
      closeDialog();
      setBronDeviceToDb({ ...data, device_name: findSelectedDevice?.name });
    }
  };

  const setBronDeviceToDb = async (data) => {
    await commonDB.connect();
    const filteredDataFromReservation = {
      device_sign: data?.device_sign,
      device_id: data.device_id,
      receipt_counter: data?.receipt_counter,
      device_name: data?.device_name,
      _id: "device",
    };
    await commonDB.getCollection("Session").edit(filteredDataFromReservation);
    getDeviceFromDb();
  };

  const handleChangeDevice = async (value) => {
    setSelectedDevice(value?.target.value);
  };
  const closeDialog = () => {
    setIsOpenChoseDevice(false);
  };

  const onScrollBottom = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    isOpenChoseDevice && getDevices();
  }, [page, isOpenChoseDevice]);

  useEffect(() => {
    if (getDeviceStatus === REQUEST_STATUSES.success) {
      if (page === 1) {
        setDevicePagingData(getDeviceData);
      } else {
        setDevicePagingData((prev) => ({
          ...prev,
          data: [...(prev?.data || []), ...(getDeviceData?.data || [])],
        }));
      }
    }
  }, [getDeviceStatus]);

  useEffect(() => {
    getDeviceToDetectIsSelectedFromDb();
  }, []);

  return (
    <Dialog onClose={closeDialog} open={isOpenChoseDevice}>
      <DialogTitle>{t("COMMON.CHOSE_DEVICES")}</DialogTitle>
      <DialogContent id="deviceList" dividers>
        <InfiniteScroll
          dataLength={devicePagingData?.data?.length || 0}
          next={onScrollBottom}
          hasMore={
            devicePagingData?.data
              ? devicePagingData?.data?.length !== devicePagingData?.total
              : false
          }
          loader={
            loading ? (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            ) : (
              ""
            )
          }
          scrollableTarget="deviceList"
        >
          <RadioGroup value={selectedDevice} onChange={handleChangeDevice}>
            {devicePagingData?.data?.map((option, index) => (
              <FormControlLabel
                checked={option?._id === selectedDevice}
                value={option?._id}
                key={index}
                control={<Radio />}
                label={option?.name}
              />
            ))}
          </RadioGroup>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ borderRadius: "12px" }}
          variant="contained"
          color="white"
          onClick={closeDialog}
        >
          {t("COMMON.CANCEL")}
        </Button>
        <Button
          sx={{ borderRadius: "12px" }}
          variant="contained"
          color="primary"
          disabled={!selectedDevice}
          onClick={reservationDevice}
        >
          {t("COMMON.SAVE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChoseDeviceDialog;
