const TransactionTypes = {
  readwrite: "readwrite",
  readonly: "readonly",
};

class IndexedDB {
  indexedDb = window.indexedDB;
  db = {};
  name = "";
  config = {};

  constructor(name, config) {
    this.name = name;
    this.config = config;
  }

  connect() {
    return new Promise((resolve, reject) => {
      const request = this.indexedDb.open(this.name, this.config.version);

      request.onupgradeneeded = this.#upgradeDB.bind(this);

      request.onsuccess = (event) => {
        this.db = event.target.result;
        resolve(this);
      };

      request.onerror = reject;
    });
  }

  getCollection(name) {
    const transaction = this.db.transaction(name, TransactionTypes.readwrite);
    const store = transaction.objectStore(name);
    return {
      store,
      add: (item) => this.#addItem(item, store),
      addAll: (items) => this.#addAllItems(items, store),
      edit: (item) => this.#editItem(item, store),
      editAll: (items) => this.#editAllItems(items, store),
      getAll: () => this.#getAllItems(store, transaction),
      getById: (id) => this.#getItemById(id, store),
      clear: () => this.#clear(store),
      delete: (id) => this.#deleteItem(id, store),
      deleteAll: (ids) => this.#deleteAllItem(ids, store),
    };
  }

  #upgradeDB(event) {
    const db = event.target.result;
    const collectionNames = db.objectStoreNames;

    this.config.collections.forEach(({ name, ...options }) => {
      if (collectionNames.contains(name)) {
        console.error(`Collection with name ${name} already exists!`);
      } else {
        db.createObjectStore(name, options);
      }
    });
  }

  #addAllItems(items, store) {
    const queries = items.map((item) => this.#addItem(item, store));
    return Promise.all(queries);
  }

  #editAllItems(items, store) {
    const queries = items.map((item) => this.#editItem(item, store));
    return Promise.all(queries);
  }

  #clear(store) {
    return new Promise((resolve, reject) => {
      const query = store.clear();
      query.onsuccess = resolve;
      query.onerror = reject;
    });
  }

  #addItem(item, store) {
    return new Promise((resolve, reject) => {
      const query = store.add(item);
      query.onsuccess = resolve;
      query.onerror = reject;
    });
  }

  #getAllItems(store, transaction) {
    return new Promise((resolve, reject) => {
      const res = [];
      const query = store.openCursor();

      query.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          res.push(cursor.value);
          cursor.continue();
        }
      };
      query.onerror = reject;

      transaction.oncomplete = (event) => {
        resolve(res);
      };
    });
  }

  #getItemById(id, store) {
    return new Promise((resolve, reject) => {
      const query = store.get(id);
      query.onsuccess = (event) => resolve(event.target.result);
      query.onerror = reject;
    });
  }

  #deleteItem(id, store) {
    return new Promise((resolve, reject) => {
      const query = store.delete(id);
      query.onsuccess = resolve;
      query.onerror = reject;
    });
  }

  #deleteAllItem(ids, store) {
    const queries = ids.map((id) => this.#deleteItem(id, store));
    return Promise.all(queries);
  }

  #editItem(item, store) {
    return new Promise((resolve, reject) => {
      const query = store.put(item);

      query.onsuccess = resolve;
      query.onerror = reject;
    });
  }
}

export default IndexedDB;
