import { lazy } from "react";
import { RouteLink } from "components/common";

const ProductionModule = lazy(() => import("../pages/production"));
const AuthLogin = lazy(() => import("../pages/auth/login/container"));
const ForgotPassword = lazy(() =>
  import("../pages/auth/forgotPassword/container")
);
const FinanceModule = lazy(() => import("../pages/finance"));
const HrModule = lazy(() => import("../pages/hr"));
const SettingsModule = lazy(() => import("../pages/settings"));
const StatisticsModule = lazy(() => import("../pages/statistics"));
const SupplierModule = lazy(() => import("../pages/supply"));
const CustomerModule = lazy(() => import("../pages/customer"));
const ProductModule = lazy(() => import("../pages/product"));
const ProductImportItem = lazy(() =>
  import("../pages/product/products/importItem/container")
);
const WarehouseModule = lazy(() => import("../pages/warehouse"));
const SaleModule = lazy(() => import("../pages/sale"));

const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const Pos = lazy(() => import("../pages/pos"));

const TaskModule = lazy(() => import("../pages/tasks"));
const Profile = lazy(() => import("../pages/profile"));

export const routers = [
  {
    new: true,
    href: "/login",
    component: AuthLogin,
    section: "default",
  },
  {
    new: true,
    href: "/forgot-password",
    component: ForgotPassword,
    section: "default",
  },
  {
    new: true,
    href: "/statistics",
    component: StatisticsModule,
    section: "statistics",
  },
  {
    new: true,
    href: "/pos",
    component: Pos,
    section: "pos",
  },
  {
    new: true,
    href: "/sale",
    component: SaleModule,
    section: "sales",
  },
  {
    new: true,
    href: "/customer",
    component: CustomerModule,
    section: "customer",
  },
  {
    new: true,
    href: "/products",
    component: ProductModule,
    section: "products",
  },
  {
    new: true,
    href: "/import/from-csv/:id",
    component: ProductImportItem,
  },

  {
    new: true,
    href: "/supply",
    component: SupplierModule,
    section: "supply",
  },
  {
    new: true,
    href: "/warehouse",
    component: WarehouseModule,
    section: "warehouse",
  },
  {
    new: true,
    href: "/finance",
    component: FinanceModule,
    section: "finance",
  },
  {
    new: true,
    href: "/hr",
    component: HrModule,
    section: "hr",
  },
  {
    new: true,
    href: "/task",
    component: TaskModule,
    section: "tasks",
  },
  {
    new: true,
    href: "/production",
    component: ProductionModule,
  },
  {
    new: true,
    href: "/settings",
    component: SettingsModule,
    section: "settings",
  },
  {
    url: "/route",
    component: () => <RouteLink />,
    section: "default",
  },
  {
    url: "/profile",
    component: () => <Profile />,
    section: "default",
  },
  {
    new: true,
    url: "*",
    component: NotFound,
    section: "default",
  },
];
