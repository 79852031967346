import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { StyledDatePicker } from "./DatePicker.style";
import { Label } from "..";
import Error from "../Error/Error";

const DatePicker = ({ inputFormat = "dd/MM/yyyy", label, error, ...props }) => {
  const renderLabel = (label) => <Label>{label}</Label>;

  const renderDateInput = () => (
    <StyledDatePicker className={error && "error"}>
      <DesktopDatePicker
        inputFormat={inputFormat}
        renderInput={(params) => <TextField {...params} id={props.id} />}
        {...props}
      />
    </StyledDatePicker>
  );

  const renderError = (error) => (
    <Error>{error?.message || "Invalid field"}</Error>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {label && renderLabel(label)}
      {renderDateInput()}
      {error && renderError(error)}
    </LocalizationProvider>
  );
};

export default DatePicker;
