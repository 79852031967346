import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { createTheme } from "@mui/material";

import { useRequest } from "hooks";
import { IndexedDB } from "utils";
import { useNetwork } from "hooks";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";

import {
  get_All_Finance_Currency_Action,
  get_hr_access_action,
  get_Organization_All_Data,
  get_Payment_All_Action,
  get_page_limit,
  get_sale_settings,
  get_currency_settings,
} from "redux/actions";
import { get_Sale_Contract_Template_Is_Main } from "redux/actions/sale/contract/template/SaleContractTemplate";
import { getSaleContractTemplateShippingLetterIsMain } from "redux/actions/sale/contract/template/SaleContractTemplateShippingLetter";
import { getToken } from "Token";
import { LOCAL_STORAGE_CONST } from "constants/localStorage.constants";
import PosDB from "pages/pos/utils/PosDB/PosDB";
import {
  useCategories,
  useCustomer,
  useProductsChange,
  useReceipt,
  useSettings,
} from "services";
import { useHr } from "services/notificationServices/container/useHr";

const useGlobal = () => {
  const { t } = useTranslation();
  const { online } = useNetwork();
  const dispatch = useDispatch();
  const [universalClient] = useRequest();
  const [syncClient] = useRequest();
  const [changingTableData, setChangingTableData] = useState();
  const [screenLoader, setScreenLoader] = useState(false);
  const [isOpenChoseDevice, setIsOpenChoseDevice] = useState(false);
  const { upsertProducts, deleteProducts } = useProductsChange();
  const { upsertCategories, deleteCategories } = useCategories();
  const { upsertCustomers, deleteCustomers } = useCustomer();
  const { upsertAccess } = useHr();
  const { upsertReceipt } = useReceipt();
  const { upsertSaleSettings, upsertOrganization, upsertPaymentMethods } =
    useSettings();

  const commonDB = useMemo(
    () =>
      getToken() &&
      new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      }),
    [getToken()]
  );

  const getSoldOfflineReceipts = async () => {
    await commonDB.connect();
    const getOrganizationIdDb = await commonDB
      .getCollection("Session")
      .getById("organizations");
    return await getOrganizationIdDb?.data?.reduce(
      async (arr, organization_id) => {
        const resolvedArray = await arr;
        const receiptDb = new PosDB(organization_id);
        const receipts = await receiptDb
          .connect()
          .then((apis) => apis.getReceipts() || []);
        return [
          ...(resolvedArray || []),
          ...(receipts.filter((receipt) => receipt.sold_offline) || []),
        ];
      },
      []
    );
  };
  const getPaymentsAndSync = async () => {
    const soldOfflineReceipts = await getSoldOfflineReceipts();
    soldOfflineReceipts?.map((receipt, index) =>
      setTimeout(() => SyncPayment(receipt), 1000 * (index + 1))
    );
  };

  const SyncPayment = async (receipt) => {
    const { organization_id } = receipt;
    const syncPaymentDb = new PosDB(organization_id);
    const responseSyncClient = await syncClient.post(
      "/back-api/admin/pos/receipt/create",
      receipt
    );
    if (responseSyncClient) {
      await syncPaymentDb
        .connect()
        .then((apis) => apis.editReceipt(responseSyncClient));
    }
  };

  const setCurrencyToDb = async () => {
    await commonDB.connect();
    const { data } = await universalClient.post(
      "/back-api/admin/finance/currency/get-all-new",
      {}
    );
    commonDB.getCollection("Currencies").addAll(data || []);
  };

  const changeOrganizationDb = async () => {
    await upsertProducts();
    await upsertCategories();
    await upsertCustomers();
    await upsertReceipt();
    await deleteProducts();
    await deleteCategories();
    await deleteCustomers();
  };

  const changeCommonDb = async () => {
    upsertAccess("initial");
    upsertOrganization();
    upsertSaleSettings();
    upsertPaymentMethods();
  };

  const setCommonDataToLocalStorage = () => {
    const imei = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONST.imei));
    if (!imei) {
      localStorage.setItem(
        LOCAL_STORAGE_CONST.imei,
        Math.floor(Math.random() * Math.pow(10, 5))
      );
    }
  };

  const getAllData = async () => {
    setScreenLoader(true);
    await dispatch(get_Organization_All_Data({}));
    await dispatch(get_All_Finance_Currency_Action({}));
    await dispatch(get_Payment_All_Action());
    await dispatch(get_hr_access_action({}));
    await dispatch(get_sale_settings({}));
    await dispatch(get_currency_settings({}));
    await dispatch(get_page_limit());
    setScreenLoader(false);
    await dispatch(
      get_Sale_Contract_Template_Is_Main({
        type: "contract",
      })
    );
    await dispatch(
      getSaleContractTemplateShippingLetterIsMain({
        type: "shipping_letter",
      })
    );
  };

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          white: {
            main: "#fff",
            contrastText: "#000",
          },
          primary: {
            main: "#026aff",
            contrastText: "#fff",
          },
          error: {
            main: "#EA5455",
          },
        },
        components: {
          MuiTablePagination: {
            defaultProps: { labelRowsPerPage: t("table.labelRowsSelect") },
          },
        },
      }),
    []
  );

  useEffect(() => {
    if (getToken() && online) {
      getAllData();
      setCurrencyToDb();
      setCommonDataToLocalStorage();
    }
  }, [getToken()]);

  useEffect(() => {
    if (online && getToken()) {
      getPaymentsAndSync();
      changeOrganizationDb();
      changeCommonDb();
    }
  }, [online, getToken()]);

  return {
    state: {
      muiTheme,
      screenLoader,
      changingTableData,
      isOpenChoseDevice,
    },
    actions: { setChangingTableData, setIsOpenChoseDevice },
  };
};

export default useGlobal;
