import Error from "../Error/Error";
import Label from "../Label/Label";
import { StyledInputMask } from "./InputMask.style";

const InputMask = ({ label, maskChar = "-", error, ...props }) => {
  const renderLabel = (label) => <Label>{label}</Label>;

  const renderInputMask = () => (
    <StyledInputMask
      className={error ? "error" : ""}
      maskChar={maskChar}
      {...props}
    />
  );

  const renderError = (error) => (
    <Error>{error?.message || "Invalid field"}</Error>
  );
  return (
    <div>
      {label && renderLabel(label)}
      {renderInputMask()}
      {error && renderError(error)}
    </div>
  );
};

export default InputMask;
