import {Api} from "../api";

export default class ProductionTemplateApi extends Api {
    getProcess(data) {
        return this.execute("post", "production/process/template/get-paging", data);
    }

    productionUniversal(url,data) {
        return this.execute("post", url, data);
    }

    createProcess(data) {
        return this.execute("post", "production/process/template/create", data);
    }


    updateProcess(data) {
        return this.execute("post", "production/process/template/update", data);
    }

    getProcessesById(data) {
        return this.execute("post", "production/process/template/get-by-id", data);
    }

    getBlockById(data) {
        return this.execute("post", "production/process/block/template/get-by-id", data);
    }

    deleteBlock(data) {
        return this.execute("post", "/production/process/block/template/delete", data);
    }

    deleteLine(data) {
        return this.execute("post", "/production/process/line/template/delete", data);
    }

    getLineById(data) {
        return this.execute("post", "/production/process/line/template/get-by-id", data);
    }

    changeCoordinates(data) {
        return this.execute("post", "production/process/block/template/set-coordinates", data);
    }

    createBlockTemplate(data) {
        return this.execute("post", "production/process/block/template/create", data);
    }

    createLine(data) {
        return this.execute("post", "/production/process/line/template/create", data);
    }

    updateBlock(data) {
        return this.execute("post", "/production/process/block/template/update", data);
    }

    attachFile(data) {
        return this.execute("post", "/production/process/attachment/add", data);
    }

    deleteFile(data) {
        return this.execute("post", "/production/process/attachment/remove", data);
    }

    addMaterial(data) {
        return this.execute("post", "/production/process/material/template/create", data);
    }

    updateLineMaterial(data) {
        return this.execute("post", "/production/process/line/template/update", data);
    }

    updateMaterialAmount(data) {
        return this.execute("post", "/production/process/material/template/update", data);
    }

    deleteMaterialAmount(data) {
        return this.execute("post", "/production/process/material/template/delete", data);
    }

    getMaterialsByLineId(data) {
        return this.execute("post", "/production/process/material/template/choose-for-line", data);
    }
}
