import toast from "react-hot-toast"
import {finance_inventory_choose} from "../../../store/finance/inventory/inventory-choose"

const finance_Inventory_Choose_Reducer = (state = finance_inventory_choose, action) => {
    switch (action.type) {
        case "FINANCE_INVENTORY_CHOOSE_0":
            return {data: action.payload.data};
        case "FINANCE_INVENTORY_CHOOSE_10001":
            return toast.error(action.payload);
        case "FINANCE_INVENTORY_CHOOSE_10000":
            return toast.error(action.payload);
        default:
            return state
    }
}
export default finance_Inventory_Choose_Reducer