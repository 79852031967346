import { useContext } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import get from "lodash.get";

import { Lane } from "../components";
import { BoardContext } from "../context";
import {
  StyledBoardContainer,
  StyledLaneContainer,
} from "../style/Board.style";

const Board = () => {
  const {
    state: { boardData },
    actions: { onDragEnd, addNewLane, editLane },
    props: { moveLane, moverCard, renderAddLane, boardContainerStyle },
  } = useContext(BoardContext);

  return (
    <DragDropContext
      onDragEnd={(onDragEndProps) =>
        onDragEnd(onDragEndProps, moveLane, moverCard)
      }
    >
      <Droppable droppableId="all-lanes" direction="horizontal" type="lane">
        {(provided) => (
          <StyledBoardContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={boardContainerStyle}
          >
            {boardData?.lanes?.map((lane, index) => (
              <Lane
                lane={lane}
                index={index}
                key={get(lane, "id", Math.random())}
              />
            ))}
            {renderAddLane ? (
              <StyledLaneContainer id="add_lane_button">
                {renderAddLane(addNewLane, editLane)}
              </StyledLaneContainer>
            ) : (
              ""
            )}
            {provided.placeholder}
          </StyledBoardContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Board;
