import {Api} from "../api";

export default class ProductionPlanApi extends Api {
    createPlan(data) {
        return this.execute("post", "/production/plan/create", data);
    }
    updatePlan(data) {
        return this.execute("post", "/production/plan/update", data);
    }
    getByIdPlan(data) {
        return this.execute("post", "/production/plan/get-by-id", data);
    }
}
