import { NavLink, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import get from "lodash.get";

import { base64 } from "utils";
import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";

import {
  SECTIONS,
  STATISTICS_DEFAULT_DATE,
  STATISTICS_PRODUCT_DEFAULT_VALUE,
  STATISTICS_SALE_DEFAULT_VALUE,
} from "./style/Navbar.constants";
import "./style/style.css";
import RoleManager from "../../services/role_management";
import { DEFAULT_ROW_LIMIT } from "components/common/PaginationTable/constants/PaginationTable.constants";

const util = (a) => base64.encode(JSON.stringify(a));

const Aside = ({ collapse, toggled, setToggled }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [hasAccess, getAccessedOrganizations] = RoleManager();
  const currentPathname = document.location.pathname;

  const globalPagingLimit =
    useSelector(
      (state) => state.get_Page_Limit_Reducer.page_limit?.data?.page_limit
    ) || DEFAULT_ROW_LIMIT;

  const reClickDisabler = (e, path) => {
    if (path === currentPathname) e.preventDefault();
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <ProSidebar
        className={"style-aside zoom-to-response"}
        collapsed={collapse}
        toggled={toggled}
        setToggled={setToggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        <SidebarContent>
          {/* <Menu style={{ paddingTop: "0" }}>
            {SECTIONS.map(
              (section) =>
                hasAccess(section.hasAccess) && (
                  <SubMenu
                    className={
                      location.pathname.startsWith(section.activeInUrl) &&
                      "active"
                    }
                    title={t(section.title)}
                    icon={
                      <LazyLoadImage
                        src={section.icon}
                        alt={section.iconAlt}
                        className="navbar-icon"
                        style={{ width: "24px" }}
                      />
                    }
                  >
                    {collapse && (
                      <Box color="grey" textAlign="center" pb={1} mr={2}>
                        {t(section.title)}
                      </Box>
                    )}
                    {section.subSections.map((subSection) => (
                      <MenuItem>
                        <NavLink
                          onClick={(e) =>
                            reClickDisabler(e, subSection.reClickDisabler)
                          }
                          to={() =>
                            subSection.to({
                              globalPagingLimit,
                              allOrganization: getAccessedOrganizations(
                                ACCESS_NAMES.all
                              ),
                            })
                          }
                          activeClassName="nav_link_active"
                        >
                          {t(subSection.title)}
                        </NavLink>
                      </MenuItem>
                    ))}
                  </SubMenu>
                )
            )}
          </Menu> */}
          <Menu style={{ paddingTop: "0" }}>
            {hasAccess(ACCESS_NAMES.statistics) && (
              <SubMenu
                className={
                  location.pathname.startsWith("/statistics") && "active"
                }
                title={t("SIDEBAR.STATISTICS.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/statistics.svg"
                    alt="sale"
                    className="navbar-icon"
                    style={{ width: "24px" }}
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.STATISTICS.TITLE")}
                  </Box>
                )}
                <MenuItem>
                  <NavLink
                    onClick={(e) => reClickDisabler(e, "/statistics/cashbox")}
                    to={`/statistics/cashbox?from_date=${util(
                      STATISTICS_DEFAULT_DATE.fromDate
                    )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`}
                    activeClassName="nav_link_active"
                  >
                    {t("SIDEBAR.STATISTICS.CASHBOX")}
                  </NavLink>
                </MenuItem>
                {hasAccess(ACCESS_NAMES.statisticsSale) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) => reClickDisabler(e, "/statistics/sale")}
                      to={`/statistics/sale?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}&from_date=${util(
                        STATISTICS_DEFAULT_DATE.fromDate
                      )}&to_date=${util(
                        STATISTICS_DEFAULT_DATE.toDate
                      )}&divider=${util(
                        STATISTICS_SALE_DEFAULT_VALUE.divider
                      )}&field=${util(
                        STATISTICS_SALE_DEFAULT_VALUE.field
                      )}&chartType=${util(
                        STATISTICS_SALE_DEFAULT_VALUE.chartType
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.ON_TRADE")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsProduct) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/products")
                      }
                      to={`/statistics/products?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}
                    &from_date=${util(
                      STATISTICS_DEFAULT_DATE.fromDate
                    )}&to_date=${util(
                        STATISTICS_DEFAULT_DATE.toDate
                      )}&chartType=${util(
                        STATISTICS_PRODUCT_DEFAULT_VALUE.chartType
                      )}&custom=${util(STATISTICS_PRODUCT_DEFAULT_VALUE.custom)}
                    `}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.ON_GOODS")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsCategory) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/sales-categories")
                      }
                      to={`/statistics/sales-categories?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}&from_date=${util(
                        STATISTICS_DEFAULT_DATE.fromDate
                      )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.ON_CATEGORIES")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsEmployee) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/sales-by-employee")
                      }
                      to={`/statistics/sales-by-employee?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}&limit=${util(
                        globalPagingLimit
                      )}&from_date=${util(
                        STATISTICS_DEFAULT_DATE.fromDate
                      )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.ON_EMPLOYEE")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsCustomer) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/sales-by-customer")
                      }
                      to={`/statistics/sales-by-customer?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}&limit=${util(
                        globalPagingLimit
                      )}&from_date=${util(
                        STATISTICS_DEFAULT_DATE.fromDate
                      )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.ON_CUSTOMER")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsPaymentMethod) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/sale-by-payment-type")
                      }
                      to={`/statistics/sale-by-payment-type?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}&limit=${util(
                        globalPagingLimit
                      )}&from_date=${util(
                        STATISTICS_DEFAULT_DATE.fromDate
                      )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.BY_TYPE_OF_PAYMENT")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsReceipt) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/all-checks")
                      }
                      to={`/statistics/all-checks?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}&limit=${util(globalPagingLimit)}&from_date=${util(
                        STATISTICS_DEFAULT_DATE.fromDate
                      )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.ALL_BOUNDARIES")}
                    </NavLink>
                  </MenuItem>
                )}

                {hasAccess(ACCESS_NAMES.statisticsWarehouse) && (
                  <MenuItem>
                    <NavLink
                      onClick={(e) =>
                        reClickDisabler(e, "/statistics/warehouse")
                      }
                      to={`/statistics/warehouse?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}&organization_id=${util(
                        get(
                          getAccessedOrganizations(ACCESS_NAMES.all),
                          "[0]._id"
                        )
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.STATISTICS.WAREHOUSE")}
                    </NavLink>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {hasAccess("pos") && (
              <MenuItem
                active={location.pathname.startsWith(
                  "/pos/choose-organization"
                )}
                icon={
                  <LazyLoadImage
                    src="/images/icons/pos.svg"
                    alt="Savd nuqtasi"
                    className="navbar-icon"
                  />
                }
              >
                <NavLink
                  to={`/pos/choose-organization`}
                  activeClassName="nav_link_active"
                >
                  {t("SIDEBAR.POS")}
                </NavLink>
              </MenuItem>
            )}
            {hasAccess("sales") && (
              <SubMenu
                className={
                  location.pathname.startsWith("/sale/contract") && "active"
                }
                title={t("SIDEBAR.SALE.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/sale_new.svg"
                    alt="sale"
                    className="navbar-icon"
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.SALE.TITLE")}
                  </Box>
                )}
                {hasAccess(ACCESS_NAMES.saleContract) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/sale/contract") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/sale/contract/list")}
                      to={`/sale/contract/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.SALE.SALE_CONTRACTS")}
                    </NavLink>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {hasAccess("customers") && (
              <SubMenu
                className={
                  location.pathname.startsWith("/customer") && "active"
                }
                title={t("SIDEBAR.CUSTOMERS.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/customer.svg"
                    alt="sale"
                    className="navbar-icon"
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.CUSTOMERS.TITLE")}
                  </Box>
                )}
                {hasAccess("sale_contract") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/customer/customers") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/customer/customers/list")
                      }
                      to={`/customer/customers/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.CUSTOMERS.TITLE")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("sale_order") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/customer/order") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/customer/orders/list")
                      }
                      to={`/customer/orders/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.CUSTOMERS.SALE_ORDERS")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess(ACCESS_NAMES.callRecord) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith(
                          "/customer/record-calls"
                        ) && "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/customer/record-calls/list")
                      }
                      to={`/customer/record-calls/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.CUSTOMERS.RECORD_CALLS")}
                    </NavLink>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {hasAccess("products") && (
              <SubMenu
                className={
                  location.pathname.startsWith("/products") && "active"
                }
                title={t("SIDEBAR.PRODUCTS.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/product.svg"
                    alt="sale"
                    className="navbar-icon"
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.PRODUCTS.TITLE")}
                  </Box>
                )}
                {hasAccess("product") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/products/products") &&
                        "nav_link_active"
                      }
                      on
                      onClick={(e) =>
                        reClickDisabler(e, "/products/products/list")
                      }
                      to={`/products/products/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTS.PRODUCTS")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("product_category") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/products/categories") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/products/categories/list")
                      }
                      to={`/products/categories/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTS.CATEGORIES")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("etiket") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/products/label") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/products/label/print")
                      }
                      to="/products/label/print"
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTS.ETIKET")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("product_income") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/products/income") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/products/income/list")
                      }
                      to={`/products/income/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTS.INCOME")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("product_transfer") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/products/transfer") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/products/transfer/list")
                      }
                      to={`/products/transfer/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTS.TRANSFER")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("product_return") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/products/return") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/products/return/list")
                      }
                      to={`/products/return/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTS.RETURN")}
                    </NavLink>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {hasAccess("supply") && (
              <SubMenu
                className={location.pathname.startsWith("/supply") && "active"}
                title={t("SIDEBAR.SUPPLY.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/supply.svg"
                    alt="supply"
                    className="navbar-icon"
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.SUPPLY.TITLE")}
                  </Box>
                )}
                {hasAccess("product_order") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith(
                          "/supply/product-orders"
                        ) && "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/supply/product-orders/list")
                      }
                      to={`/supply/product-orders/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.SUPPLY.PRODUCT_ORDERS")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("material_order") &&
                  document.location.host !== "oson-sot.uz" && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith(
                            "/supply/material-orders"
                          ) && "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/supply/material-orders/list")
                        }
                        to={`/supply/material-orders/list?page=${util(
                          0
                        )}&limit=${util(globalPagingLimit)}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.SUPPLY.MATERIAL_ORDERS")}
                      </NavLink>
                    </MenuItem>
                  )}
                {hasAccess("supplier") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/supply/supplier") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/supply/supplier/list")
                      }
                      to={`/supply/supplier/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.SUPPLY.SUPPLIERS")}
                    </NavLink>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {hasAccess("warehouse") &&
              document.location.host !== "oson-sot.uz" && (
                <SubMenu
                  className={
                    location.pathname.startsWith("/warehouse") && "active"
                  }
                  title={t("SIDEBAR.WAREHOUSE.TITLE")}
                  icon={
                    <LazyLoadImage
                      src="/images/icons/warehouse.svg"
                      alt="sklad"
                      className="navbar-icon"
                    />
                  }
                >
                  {collapse && (
                    <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                      {t("SIDEBAR.WAREHOUSE.TITLE")}
                    </Box>
                  )}
                  {hasAccess("material") && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith("/warehouse/tmb") &&
                          "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/warehouse/tmb/list")
                        }
                        to={`/warehouse/tmb/list?page=${util(0)}&limit=${util(
                          globalPagingLimit
                        )}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.WAREHOUSE.TMB")}
                      </NavLink>
                    </MenuItem>
                  )}
                  {hasAccess("material_category") && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith(
                            "/warehouse/active-category"
                          ) && "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/warehouse/active-category/list")
                        }
                        to={`/warehouse/active-category/list?page=${util(
                          0
                        )}&limit=${util(globalPagingLimit)}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.WAREHOUSE.CATEGORIES")}
                      </NavLink>
                    </MenuItem>
                  )}
                  {hasAccess("active") && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith("/warehouse/active/") &&
                          "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/warehouse/active/list")
                        }
                        to={`/warehouse/active/list?page=${util(
                          0
                        )}&limit=${util(globalPagingLimit)}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.WAREHOUSE.ACTIVES")}
                      </NavLink>
                    </MenuItem>
                  )}
                  {hasAccess("material_income") && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith("/warehouse/income") &&
                          "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/warehouse/income/list")
                        }
                        to={`/warehouse/income/list?page=${util(
                          0
                        )}&limit=${util(globalPagingLimit)}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.WAREHOUSE.INCOME")}
                      </NavLink>
                    </MenuItem>
                  )}
                  {hasAccess("material_transfer") && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith("/warehouse/transfer") &&
                          "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/warehouse/transfer/list")
                        }
                        to={`/warehouse/transfer/list?page=${util(
                          0
                        )}&limit=${util(globalPagingLimit)}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.WAREHOUSE.TRANSFER")}
                      </NavLink>
                    </MenuItem>
                  )}
                  {hasAccess("material_return") && (
                    <MenuItem>
                      <NavLink
                        className={
                          location.pathname.startsWith("/warehouse/return") &&
                          "nav_link_active"
                        }
                        onClick={(e) =>
                          reClickDisabler(e, "/warehouse/return/list")
                        }
                        to={`/warehouse/return/list?page=${util(
                          0
                        )}&limit=${util(globalPagingLimit)}`}
                        activeClassName="nav_link_active"
                      >
                        {t("SIDEBAR.WAREHOUSE.RETURN")}
                      </NavLink>
                    </MenuItem>
                  )}
                </SubMenu>
              )}

            {hasAccess("finance") && (
              <SubMenu
                className={location.pathname.startsWith("/finance") && "active"}
                title={t("SIDEBAR.FINANCE.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/finance.svg"
                    alt="moliya"
                    className="navbar-icon"
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.FINANCE.TITLE")}
                  </Box>
                )}
                {hasAccess("cashbox") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/finance/cash") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/finance/cash")}
                      to={`/finance/cash?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.FINANCE.CASH")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess("inventorization") && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/finance/revision") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/finance/revision/list")
                      }
                      to={`/finance/revision/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.FINANCE.INVENTORY")}
                    </NavLink>
                  </MenuItem>
                )}
                <MenuItem>
                  <NavLink
                    className={
                      location.pathname.startsWith("/finance/currency") &&
                      "nav_link_active"
                    }
                    onClick={(e) =>
                      reClickDisabler(e, "/finance/currency/list")
                    }
                    to={`/finance/currency/list?page=${util(0)}&limit=${util(
                      globalPagingLimit
                    )}`}
                    activeClassName="nav_link_active"
                  >
                    {t("SIDEBAR.FINANCE.CURRENCY")}
                  </NavLink>
                </MenuItem>
              </SubMenu>
            )}

            {hasAccess(ACCESS_NAMES.hr) && (
              <SubMenu
                className={location.pathname.startsWith("/hr") && "active"}
                title={t("SIDEBAR.HR.TITLE")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/hr.svg"
                    alt="moliya"
                    className="navbar-icon"
                  />
                }
              >
                {collapse && (
                  <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                    {t("SIDEBAR.HR.TITLE")}
                  </Box>
                )}
                {hasAccess(ACCESS_NAMES.employee) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/hr/employees") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/hr/employees/list")}
                      to={`/hr/employees/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.HR.EMPLOYEES")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess(ACCESS_NAMES.section) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/hr/section") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/hr/section/list")}
                      to={`/hr/section/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.HR.SECTION")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess(ACCESS_NAMES.position) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/hr/position") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/hr/position/list")}
                      to={`/hr/position/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.HR.POSITION")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess(ACCESS_NAMES.role) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/hr/role") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/hr/role/list")}
                      to={`/hr/role/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.HR.ROLES")}
                    </NavLink>
                  </MenuItem>
                )}
                {hasAccess(ACCESS_NAMES.attendance) && (
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/hr/attendance") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/hr/attendance/list")}
                      to={`/hr/attendance/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.HR.ATTENDANCE")}
                    </NavLink>
                  </MenuItem>
                )}
              </SubMenu>
            )}

            {hasAccess("tasks") && (
              <MenuItem
                active={location.pathname.startsWith("/task/board")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/task.svg"
                    alt="sale"
                    className="navbar-icon"
                  />
                }
              >
                <NavLink
                  className={
                    location.pathname.startsWith("/task/board") &&
                    "nav_link_active"
                  }
                  onClick={(e) => reClickDisabler(e, "/task/board/list")}
                  to={`/task/board/list`}
                  activeClassName="nav_link_active"
                >
                  {t("SIDEBAR.TASKS.BOARD")}
                </NavLink>
              </MenuItem>
            )}

            {hasAccess("production") &&
              window.location.host.includes("localhost") && (
                <SubMenu
                  className={
                    location.pathname.startsWith("/production") && "active"
                  }
                  title={t("SIDEBAR.PRODUCTION.TITLE")}
                  icon={
                    <LazyLoadImage
                      src="/images/icons/production.svg"
                      alt="sale"
                      className="navbar-icon"
                    />
                  }
                >
                  {collapse && (
                    <Box color={"grey"} textAlign={"center"} pb={1} mr={2}>
                      {t("SIDEBAR.PRODUCTION.TITLE")}
                    </Box>
                  )}
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/production/planing") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/production/planing/list")
                      }
                      to={`/production/planing/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTION.PLANNING")}
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/production/board") &&
                        "nav_link_active"
                      }
                      onClick={(e) => reClickDisabler(e, "/production/board")}
                      to="/production/board"
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTION.BOARD")}
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/production/order") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/production/orders/list")
                      }
                      to={`/production/orders/list?page=${util(0)}&limit=${util(
                        globalPagingLimit
                      )}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTION.ORDERS")}
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/production/process") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/production/process/list")
                      }
                      to={`/production/process/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      {t("SIDEBAR.PRODUCTION.PROCESS")}
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink
                      className={
                        location.pathname.startsWith("/production/template") &&
                        "nav_link_active"
                      }
                      onClick={(e) =>
                        reClickDisabler(e, "/production/template/list")
                      }
                      to={`/production/template/list?page=${util(
                        0
                      )}&limit=${util(globalPagingLimit)}`}
                      activeClassName="nav_link_active"
                    >
                      <h5 className="font-style-2">
                        {t("SIDEBAR.PRODUCTION.TEMPLATE")}
                      </h5>
                    </NavLink>
                  </MenuItem>
                </SubMenu>
              )}
            {hasAccess("settings") && (
              <MenuItem
                active={location.pathname.startsWith("/settings")}
                icon={
                  <LazyLoadImage
                    src="/images/icons/settings.svg"
                    alt="Settings"
                    className="navbar-icon"
                  />
                }
              >
                <NavLink
                  to={`/settings/tabs?tabIndex=${util(
                    hasAccess(ACCESS_NAMES.paymentMethods)
                      ? 1
                      : hasAccess(ACCESS_NAMES.receiptDetailGet)
                      ? 2
                      : 3
                  )}`}
                  activeClassName="nav_link_active"
                >
                  {t("SIDEBAR.SETTINGS")}
                </NavLink>
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default Aside;
