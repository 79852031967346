import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledButton = styled(Button)({
  borderRadius: "12px",
});

export const StyledTypography = styled(Typography)({
  fontSize: "15px",
  marginLeft: "5px",
  fontWeight: 500,
  lineHeight: "26px",
});
