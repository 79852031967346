import axios from "axios";
import { getToken } from "../../Token";
import error_message_handler from "services/error_message_handler";
import toast from "react-hot-toast";

/**
 * Core API, takes care request and response
 */
class PosApiCall {
  constructor(baseUrl = process.env.REACT_APP_BASE_TEST_URL) {
    this.api2client = axios.create({
      baseURL: `${baseUrl}`,
      // baseURL: `http://localhost:5000/admin`
      timeout: 20000,
    });
    this.api2client.defaults.params = {};
    this.query = {};
    this.token = getToken();
    this.setInterceptors();
  }

  setInterceptors() {
    this.api2client.interceptors.response.use(
      (resp) => {
        if (resp.data) {
          if (typeof resp.data === "string") {
            resp.data = {
              data: resp.data,
              status: resp.status,
            };
          } else {
            resp.data.status = resp.status;
          }
        }
        return resp;
      },
      (err) => {
        throw err;
      }
    );
  }

  async execute(
    method,
    url,
    data,
    onUploadProgress,
    toastMessage = true,
    additionalHeader = {}
  ) {
    const accessToken = getToken();
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: accessToken,
        ...additionalHeader,
      };
    }
    const urlSplit = url.split("/").slice(-1)[0];
    const urlEndGetPaging = urlSplit.split("-").slice(-1)[0];
    const urlStartGetPaging = urlSplit.split("-").slice(0)[0];
    const notLoadUrlList = [
      "get-paging",
      "get-by-id",
      "get-recon",
      "get-for-choose",
      "choose",
      "pagin",
      "warehouse",
      "get",
      "get-all",
    ];

    try {
      if (
        !notLoadUrlList.includes(urlSplit) &&
        !notLoadUrlList.includes(urlEndGetPaging) &&
        !notLoadUrlList.includes(urlStartGetPaging)
      ) {
        document.getElementById("generalLoader").style.display = "block";
      }
      const resp = await this.api2client({
        method,
        url,
        data,
        headers,
        onUploadProgress,
      });
      if (
        !notLoadUrlList.includes(urlSplit) &&
        !notLoadUrlList.includes(urlEndGetPaging) &&
        !notLoadUrlList.includes(urlStartGetPaging)
      ) {
        document.getElementById("generalLoader").style.display = "none";
      }

      if (toastMessage && (url.endsWith("update") || url.endsWith("set"))) {
        toast.success("Successful updated");
      } else if (url.endsWith("create")) {
        toast.success("Successful created");
      } else if (url.includes("delete")) {
        toast.success("Successful deleted");
      }
      return resp.data;
    } catch (err) {
      const code = err.response?.data?.code;
      if (
        !notLoadUrlList.includes(urlSplit) &&
        !notLoadUrlList.includes(urlEndGetPaging) &&
        !notLoadUrlList.includes(urlStartGetPaging)
      ) {
        document.getElementById("generalLoader").style.display = "none";
      }
      if (toastMessage) error_message_handler(code);
      const error = err.response;
      if (!error) {
        error_message_handler(12163);
        throw err;
      } else throw error.data;
    }
  }

  setQuery(key, value) {
    if (!this.query) {
      this.query = {};
    }
    this.query[key] = value;
    this.queryString = null;

    // eslint-disable-next-line
    for (const i in this.query) {
      if (this.queryString === null) {
        this.queryString = "?";
      } else {
        this.queryString += "&";
      }

      this.queryString += `${i}=${this.query[i]}`;
    }
  }

  existToken() {
    return this.token.exist();
  }

  clearToken() {
    this.token.clear();
  }

  /**
   * common api
   */
}

export { PosApiCall };
