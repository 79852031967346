import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import currencyFormatter from "services/number_format/currencyFormatter";

import Input from "../Input/Input";

const ControlledMoneyInput = ({
  control,
  name,
  rules,
  errors,
  setValue,
  ...props
}) => {
  const inputChangeHandler = (e, formChangeHandler) => {
    formChangeHandler(e);
    const { onChange } = props;
    if (onChange) {
      onChange(e);
    }
    setValue(name, currencyFormatter(e.target.value));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          id={name}
          {...props}
          {...field}
          onChange={(e) => inputChangeHandler(e, field.onChange)}
          hookFormChangeHandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default ControlledMoneyInput;
