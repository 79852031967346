import get from "lodash.get";
import moment from "moment";

import { ACCESS_NAMES } from "services/role_management/RoleManager.constants";
import { base64 } from "utils";

const util = (a) => base64.encode(JSON.stringify(a));

export const STATISTICS_DEFAULT_DATE = {
  fromDate: moment(new Date()).startOf("month").toISOString(),
  toDate: moment(new Date()).endOf("day").toISOString(),
};

export const STATISTICS_SALE_DEFAULT_VALUE = {
  divider: "day",
  field: "gross_sales",
  chartType: "bar",
};

export const STATISTICS_PRODUCT_DEFAULT_VALUE = {
  chartType: "bar",
  custom: false,
};

export const STATISTICS_SALE_BY_PAYMENT_DEFAULT_VALUE = {
  saleType: "sale_contract",
};

export const STATISTICS_WAREHOUSE_DEFAULT_VALUE = {};

export const SECTIONS = [
  {
    hasAccess: ACCESS_NAMES.statistics,
    activeInUrl: "/statistics",
    title: "SIDEBAR.STATISTICS.TITLE",
    icon: "/images/icons/statistics.svg",
    iconAlt: "statistics",
    subSections: [
      {
        hasAccess: ACCESS_NAMES.statisticsSale,
        reClickDisabler: "/statistics/sale",
        title: "SIDEBAR.STATISTICS.ON_TRADE",
        to: ({ globalPagingLimit }) =>
          `/statistics/sale?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&from_date=${util(STATISTICS_DEFAULT_DATE.fromDate)}&to_date=${util(
            STATISTICS_DEFAULT_DATE.toDate
          )}&divider=${util(
            STATISTICS_SALE_DEFAULT_VALUE.divider
          )}&field=${util(
            STATISTICS_SALE_DEFAULT_VALUE.field
          )}&chartType=${util(STATISTICS_SALE_DEFAULT_VALUE.chartType)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsProduct,
        reClickDisabler: "/statistics/products",
        title: "SIDEBAR.STATISTICS.ON_GOODS",
        to: ({ globalPagingLimit }) =>
          `/statistics/products?page=${util(0)}&limit=${util(globalPagingLimit)}
                    &from_date=${util(
                      STATISTICS_DEFAULT_DATE.fromDate
                    )}&to_date=${util(
            STATISTICS_DEFAULT_DATE.toDate
          )}&chartType=${util(
            STATISTICS_PRODUCT_DEFAULT_VALUE.chartType
          )}&custom=${util(STATISTICS_PRODUCT_DEFAULT_VALUE.custom)}
                    `,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsCategory,
        reClickDisabler: "/statistics/sales-categories",
        title: "SIDEBAR.STATISTICS.ON_CATEGORIES",
        to: ({ globalPagingLimit }) =>
          `/statistics/sales-categories?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&from_date=${util(STATISTICS_DEFAULT_DATE.fromDate)}&to_date=${util(
            STATISTICS_DEFAULT_DATE.toDate
          )}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsEmployee,
        reClickDisabler: "/statistics/sales-by-employee",
        title: "SIDEBAR.STATISTICS.ON_EMPLOYEE",
        to: ({ globalPagingLimit }) =>
          `/statistics/sales-by-employee?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsCustomer,
        reClickDisabler: "/statistics/sales-by-customer",
        title: "SIDEBAR.STATISTICS.ON_CUSTOMER",
        to: ({ globalPagingLimit }) =>
          `/statistics/sales-by-customer?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsPaymentMethod,
        reClickDisabler: "/statistics/sale-by-payment-type",
        title: "SIDEBAR.STATISTICS.BY_TYPE_OF_PAYMENT",
        to: ({ globalPagingLimit }) =>
          `/statistics/sale-by-payment-type?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsReceipt,
        reClickDisabler: "/statistics/all-checks",
        title: "SIDEBAR.STATISTICS.ALL_BOUNDARIES",
        to: ({ globalPagingLimit }) =>
          `/statistics/all-checks?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&limit=${util(globalPagingLimit)}&from_date=${util(
            STATISTICS_DEFAULT_DATE.fromDate
          )}&to_date=${util(STATISTICS_DEFAULT_DATE.toDate)}`,
      },
      {
        hasAccess: ACCESS_NAMES.statisticsWarehouse,
        reClickDisabler: "/statistics/warehouse",
        title: "SIDEBAR.STATISTICS.WAREHOUSE",
        to: ({ globalPagingLimit, allOrganization }) =>
          `/statistics/warehouse?page=${util(0)}&limit=${util(
            globalPagingLimit
          )}&organization_id=${util(get(allOrganization, "[0]._id"))}`,
      },
    ],
  },
];
