import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import toast from "react-hot-toast";

import {
  ToastClose,
  ToastContainer,
  ToastContent,
  ToastIcon,
} from "./useCustomToastNotification.style";

const useCustomToastNotification = () => {
  const [_, setShow] = useState(false);

  const handleClickTargetBlank = async (toastId, targetLink) => {
    const url = await `${window.location.origin}${targetLink}`;
    const link = await document.createElement("a");
    link.href = await url;
    await link.setAttribute("target", "_blank");
    await document.body.appendChild(link);
    await link.click();
    await handleCloseToast(toastId);
  };

  const handleCloseToast = (toastId) => {
    toast.dismiss(toastId);
    setShow((prevState) => !prevState);
  };

  const notification = (content, linkText, isButtonFirst = false, link) => {
    return toast.custom(
      (toast) => (
        <ToastContainer
          className={`${
            toast.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <ToastIcon>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.827 46.707C26.4785 47.4324 26.2891 48.2374 26.2891 49.0695C26.2891 51.0189 27.329 52.8202 29.0173 53.7949C30.7055 54.7696 32.7854 54.7696 34.4736 53.7949C36.1619 52.8202 37.2018 51.0189 37.2018 49.0695C37.2018 48.2374 37.0124 47.4324 36.6639 46.707H26.827Z"
                fill="#EB5757"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.4536 15.2034C28.1116 15.2034 24.9065 16.531 22.5434 18.8941L22.0493 19.3882C19.6861 21.7514 18.3585 24.9565 18.3585 28.2985L18.3585 30.61C18.3585 34.8643 16.6685 38.9444 13.6602 41.9526V41.9526C13.1381 42.4747 12.8447 43.1829 12.8447 43.9213C12.8447 45.459 14.0912 46.7055 15.6289 46.7055H19.8683C19.8797 46.7057 19.8912 46.7059 19.9027 46.7059H43.5914C43.6029 46.7059 43.6144 46.7057 43.6259 46.7055L47.8635 46.7055C49.4012 46.7055 50.6477 45.459 50.6477 43.9213C50.6477 43.1829 50.3544 42.4747 49.8322 41.9526V41.9526C46.825 38.9454 45.1356 34.8669 45.1356 30.6141V28.2985C45.1356 24.9565 43.808 21.7514 41.4449 19.3882L40.9507 18.8941C38.5876 16.531 35.3825 15.2034 32.0405 15.2034H31.4536Z"
                fill="#F2C94C"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.3225 15.2693V12.8421C33.3225 11.9721 32.6172 11.2668 31.7472 11.2668C30.8772 11.2668 30.1719 11.9721 30.1719 12.8421V15.2694C30.5961 15.226 31.024 15.204 31.4543 15.204H32.0411C32.471 15.204 32.8986 15.2259 33.3225 15.2693Z"
                fill="#F2994A"
              />
              <path
                d="M50.6477 27.8062C50.6477 25.324 50.1588 22.8661 49.2089 20.5729C48.259 18.2796 46.8668 16.1959 45.1116 14.4408"
                stroke="#EB5757"
                strokeWidth="3.15025"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.8455 27.8063C12.8455 25.3242 13.3344 22.8663 14.2842 20.5731C15.2341 18.2798 16.6264 16.1961 18.3816 14.441"
                stroke="#EB5757"
                strokeWidth="3.15025"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ToastIcon>
          <ToastContent>
            {!isButtonFirst && content}
            <Button onClick={() => handleClickTargetBlank(toast.id, link)}>
              {linkText}
            </Button>
            {isButtonFirst && content}
          </ToastContent>
          <ToastClose>
            <IconButton onClick={() => handleCloseToast(toast.id)} size="large">
              <svg
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.12 4.88L4.88 9.12"
                  stroke="#EA5455"
                  fill="#EA5455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.88 4.88L9.12 9.12"
                  stroke="#EA5455"
                  fill="#EA5455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z"
                  stroke="#EA5455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </ToastClose>
        </ToastContainer>
      ),
      { icon: "👏", duration: 100000 }
    );
  };
  return { notification };
};

export default useCustomToastNotification;
