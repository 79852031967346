export const LOCAL_STORAGE_CONST = {
  imei: "imei",
  organization: "organization",
  status: "status",
  productUpdatedAt: "productUpdatedAt",
  categoryUpdatedAt: "categoryUpdatedAt",
  customerUpdatedAt: "customerUpdatedAt",
  receiptUpdatedAt: "receiptUpdatedAt",
  productDeletedAt: "productDeletedAt",
  categoryDeletedAt: "categoryDeletedAt",
  customerDeletedAt: "customerDeletedAt",
  receiptDeletedAt: "receiptDeletedAt",
  userData: "userData",
};

export const POS_DB_STATUS_VALUE = {
  initial: "initial",
  created: "created",
  updated: "updated",
};
