import error_message_handler from "services/error_message_handler";
import { postAxios } from "../../../api";

export const get_All_Finance_Currency_Action = (state) => async (dispatch) => {
  try {
    await postAxios(`/back-api/admin/finance/currency/get-all-new`, state)
      .then((response) => {
        return dispatch({
          type: "GET_ALL_FINANCE_CURRENCY_0",
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/login");
        } else {
          error_message_handler(error.response.data.code);
        }
        if (error.response) {
          const code = error.response.data.code;
          switch (code) {
            case 10000:
              dispatch({
                type: "GET_ALL_FINANCE_CURRENCY_10000",
                payload: error.response.data.message,
              });
              break;
            default:
              return error;
          }
        }
      });
  } catch (err) {}
};
