import { useEffect, useMemo, useState } from "react";

import { IndexedDB } from "utils";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";
import { getToken } from "Token";
import { useSelector } from "react-redux";
import { useNetwork } from "hooks";

const RoleManager = () => {
  const organizations = useSelector(
    (state) => state.get_Organization_All_Reducer?.data
  );
  const rolesFromServer = useSelector(
    (state) => state.get_hr_access_reducer?.data
  );

  const [accessesList, setAccessesList] = useState(rolesFromServer);
  const [organizationList, setOrganizationList] = useState();
  const { online } = useNetwork();

  const commonDB = useMemo(
    () =>
      getToken() &&
      new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      }),
    [getToken()]
  );

  function hasAccess(section_name, organization_id) {
    if (accessesList?.code === 0) {
      if (accessesList?.data.length === 0) {
        return true;
      } else {
        let hasAccess = false;
        for (const role of accessesList?.data) {
          if (organization_id && role.organization_id === organization_id) {
            hasAccess = role.role[section_name];
            return hasAccess;
          }
          hasAccess = hasAccess || role.role[section_name];
        }
        if (organization_id) return false;
        return hasAccess;
      }
    } else {
      return false;
    }
  }

  function getAccessedOrganizations(section_name) {
    if (accessesList?.code === 0) {
      if (accessesList?.data.length === 0 || section_name === "all") {
        return online ? organizations?.data : organizationList;
      } else {
        const organizationsHash = {};
        (online ? organizations?.data : organizationList)?.forEach(
          (organization) => {
            organizationsHash[organization._id] = organization;
          }
        );
        let accessedOnes = [];
        accessesList?.data.forEach((role) => {
          if (role.role[section_name])
            accessedOnes.push(organizationsHash[role.organization_id]);
        });
        return accessedOnes;
      }
    } else {
      return [];
    }
  }
  const getOrganizationsFromDb = async () => {
    await commonDB.connect();
    const data = await commonDB.getCollection("Organizations").getAll();
    setOrganizationList(data || []);
  };
  const getAccessesFromDb = async () => {
    await commonDB.connect();
    const data = await commonDB.getCollection("Accesses").getById("hr");
    setAccessesList(data?.value);
  };
  useEffect(() => {
    if (getToken() && !online) {
      getOrganizationsFromDb();
    }
    if (getToken()) {
      getAccessesFromDb();
    }
  }, [getToken(), online]);
  return [hasAccess, getAccessedOrganizations];
};

export default RoleManager;
