import { Controller } from "react-hook-form";

import DatePicker from "../DatePicker/DatePicker";

const ControlledDatePicker = ({ name, control, rules, errors, ...props }) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <DatePicker id={name} {...props} {...field} error={errors?.[name]} />
      )}
    />
  );
};

export default ControlledDatePicker;
