import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  BoxButtonWrapperStyle,
  BoxContainerStyle,
  CancelButton,
  SaveButton,
} from "./SubmitButtons.style";

const SubmitButtons = ({
  children,
  childrenPosition = "start",
  onClickCancel,
  onClickSubmit,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <BoxContainerStyle>
      {childrenPosition === "start" && children}
      <BoxButtonWrapperStyle>
        <CancelButton
          variant="contained"
          onClick={onClickCancel ? onClickCancel : () => history.goBack()}
          color="white"
          className="radius-12"
        >
          {t("COMMON.CANCEL")}
        </CancelButton>
        <SaveButton
          className="radius-12"
          onClick={onClickSubmit && onClickSubmit}
          variant="contained"
          color="primary"
          type={onClickSubmit ? "button" : "submit"}
        >
          {t("COMMON.SAVE")}
        </SaveButton>
      </BoxButtonWrapperStyle>
      {childrenPosition === "end" && <>&nbsp; &nbsp; &nbsp;</>}
      {childrenPosition === "end" && children}
    </BoxContainerStyle>
  );
};

export default SubmitButtons;
