import { toast } from "react-hot-toast";
import { postAxios } from "../../../api";

export const receive_Warehouse_Transfer_Action =
  (state, history) => async (dispatch) => {
    try {
      await postAxios(
        `/back-api/admin/warehouse/material/transfer/receive`,
        state
      )
        .then((response) => {
          return dispatch({
            type: "RECEIVE_WAREHOUSE_TRANSFER_0",
            payload: { response, history },
          });
        })
        .catch((error) => {
          if (error.response) {
            const code = error.response.data.code;
            switch (code) {
              case 37006:
                dispatch({
                  type: "RECEIVE_WAREHOUSE_TRANSFER_37006",
                  payload: error.message.data.message,
                });
                break;
              case 37003:
                dispatch({
                  type: "RECEIVE_WAREHOUSE_TRANSFER_37003",
                  payload: error.response.data.message,
                });
                break;
              case 10001:
                dispatch({
                  type: "RECEIVE_WAREHOUSE_TRANSFER_10001",
                  payload: error.response.data.message,
                });
                break;
              case 10000:
                dispatch({
                  type: "RECEIVE_WAREHOUSE_TRANSFER_10000",
                  payload: error.response.data.message,
                });
                break;
              default:
                return error;
            }
          }
        });
    } catch (err) {
      return toast.error("ware-house transfer receive server error");
    }
  };
