import { IndexedDB } from "utils";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";
import { useRequest } from "hooks";

export const useSettings = () => {
  const [universalClient] = useRequest();
  const commonDB = new IndexedDB(COMMON_DB, {
    collections: COMMON_DB_COLLECTIONS,
  });

  const upsertSaleSettings = async (notificationData) => {
    await commonDB.connect();
    const data = await universalClient.post(
      "/back-api/admin/settings/sale-settings/get",
      {}
    );
    commonDB.getCollection("SaleSettings").edit(data || []);
  };

  const upsertOrganization = async (notificationData) => {
    await commonDB.connect();
    const data = await universalClient.post(
      "/back-api/admin/settings/organization/get-all",
      {}
    );
    commonDB.getCollection("Organizations").editAll(data || []);
  };

  const upsertPaymentMethods = async (notificationData) => {
    await commonDB.connect();
    const data = await universalClient.post(
      "/back-api/admin/settings/payment-method/get-all",
      { op: "" }
    );
    commonDB.getCollection("PaymentMethods").editAll(data?.data || []);
  };

  return { upsertSaleSettings, upsertOrganization, upsertPaymentMethods };
};
