import {
  Skeleton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";

import TableRow from "../../Table/components/TableRow";

const TableSkeleton = ({ columns, tableBodyRowCount = 5 }) => {
  return (
    <MuiTable>
      <TableHead>
        <MuiTableRow>
          {columns?.map((item, index) => (
            <TableCell key={index}>
              <Skeleton
                sx={{ backgroundColor: "rgba(94, 88, 115,0.4)" }}
                variant="text"
              />
            </TableCell>
          ))}
        </MuiTableRow>
      </TableHead>
      <TableBody>
        {[...Array(tableBodyRowCount).keys()].map((_, index) => (
          <TableRow key={index}>
            {columns?.map((item, index) => (
              <TableCell key={index}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
};

export default TableSkeleton;
