import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function CurrencySelect(props) {
  const {
    className,
    setFirst,
    value,
    label,
    name,
    onChange,
    required = false,
    variant,
    isMain = false,
    fullWidth,
    label_child,
    disabled = false,
  } = props;
  const [currencies, setItems] = useState([]);

  const temp_currencies = useSelector(
    (state) => state.get_All_Finance_Currency_Reducer.data
  );

  function setMainCurrency() {
    for (let i = 0; i < currencies.length; ++i) {
      if (currencies[i].is_main) {
        const e = {
          target: {
            name: name,
            value: currencies[i]._id,
          },
        };
        onChange(e);
      }
    }
  }

  useMemo(() => {
    if (isMain) setMainCurrency();
  }, [currencies]);

  async function getCurrencies() {
    try {
      const data = temp_currencies.data;
      setItems(data);
    } catch (e) {}
  }

  useEffect(() => {
    if (temp_currencies.data) {
      getCurrencies();
    }
  }, [temp_currencies]);
  return (
    <div>
      <FormControl
        variant={variant}
        fullWidth={fullWidth}
        className={className}
      >
        <InputLabel className={label_child}>{label}</InputLabel>
        <Select
          label={label}
          value={setFirst && !value ? currencies[0]?._id : value}
          name={name}
          disabled={disabled}
          onChange={onChange}
          required={required}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {currencies.map((item, index) => (
            <MenuItem key={index} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CurrencySelect;
