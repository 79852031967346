export const ACCESS_NAMES = {
  all: "all",
  isBoss: "is_boss",

  // statistics
  statistics: "statistics",
  statisticsSale: "statistics_sale",
  statisticsProduct: "statistics_product",
  statisticsCategory: "statistics_category",
  statisticsEmployee: "statistics_employee",
  statisticsCustomer: "statistics_customer",
  statisticsPaymentMethod: "statistics_payment_method",
  statisticsReceipt: "statistics_receipt",
  statisticsWarehouse: "statistics_warehouse",

  // Poss
  pos: "pos",

  // Sale
  saleContract: "sale_contract",
  saleContractCreate: "sale_contract_create",
  saleContractUpdate: "sale_contract_update",
  saleContractDelete: "sale_contract_delete",

  // Customer customers
  customer: "customer",
  customerCreate: "customer_create",
  customerUpdate: "customer_update",
  callRecord: "call_record",
  callRecordUpdate: "call_record_update",
  callRecordDelete: "call_record_delete",
  // customer order
  saleOrder: "sale_order",
  saleOrderCreate: "sale_order_create",
  saleOrderUpdate: "sale_order_update",

  // product
  product: "product",
  productCreate: "product_create",
  productUpdate: "product_update",
  // product category
  productCategory: "product_category",
  productCategoryCreate: "product_category_create",
  productCategoryUpdate: "product_category_update",
  // Etiketka
  label: "etiket",
  // product income
  productIncome: "product_income",
  productIncomeCreate: "product_income_create",
  // product transfer
  productTransfer: "product_transfer",
  productTransferCreate: "product_transfer_create",
  productTransferUpdate: "product_transfer_update",
  // product return
  productReturn: "product_return",
  productReturnCreate: "product_return_create",

  // supply productOrder
  productOrder: "product_order",
  productOrderCreate: "product_order_create",
  productOrderUpdate: "product_order_update",
  // supply materialOrder
  materialOrder: "material_order",
  materialOrderCreate: "material_order_create",
  materialOrderUpdate: "material_order_update",
  // supply supplier
  supplier: "supplier",
  supplierCreate: "supplier_create",
  supplierUpdate: "supplier_update",
  supplierDelete: "supplier_delete",

  // warehouse tmb
  material: "material",
  materialCreate: "material_create",
  materialUpdate: "material_update",
  materialDelete: "material_delete",
  // warehouse category
  materialCategory: "material_category",
  materialCategoryCreate: "material_category_create",
  materialCategoryUpdate: "material_category_update",
  materialCategoryDelete: "material_category_delete",
  // warehouse active
  active: "active",
  activeCreate: "active_create",
  activeUpdate: "active_update",
  activeDelete: "active_delete",
  // warehouse income
  materialIncome: "material_income",
  materialIncomeCreate: "material_income_create",
  materialIncomeUpdate: "material_income_update",
  // warehouse transfer'
  materialTransfer: "material_transfer",
  materialTransferCreate: "material_transfer_create",
  materialTransferUpdate: "material_transfer_update",
  // warehouse return
  materialReturn: "material_return",
  materialReturnCreate: "material_return_create",
  materialReturnUpdate: "material_return_update",

  // finance cash
  cashbox: "cashbox",
  cashboxDelete: "cashbox_delete",
  cashIncome: "cash_income",
  cashExpense: "cash_expense",
  cashTransfer: "cash_transfer",
  cashExchange: "cash_exchange",
  cashboxCreate: "cashbox_create",
  // finance inventorization
  inventorization: "inventorization",
  inventorizationCreate: "inventorization_create",
  inventorizationUpdate: "inventorization_update",
  // finance currency
  currency: "currency",
  currencyCreate: "currency_create",
  currencyUpdate: "currency_update",
  currencyDelete: "currency_delete",

  // Hr employee access
  hr: "hr",
  employee: "employee",
  employeeCreate: "employee_create",
  employeeUpdate: "employee_update",
  employeeDelete: "employee_delete",
  // Hr section access
  section: "section",
  sectionCreate: "section_create",
  sectionUpdate: "section_update",
  sectionDelete: "section_delete",
  // Hr position access
  position: "position",
  positionCreate: "position_create",
  positionUpdate: "position_update",
  positionDelete: "position_delete",
  // Hr role access
  role: "role",
  roleCreate: "role_create",
  roleUpdate: "role_delete",
  roleDelete: "role_delete",
  attendance: "attendance",
  attendanceCreate: "attendance_create",
  attendanceUpdate: "attendance_update",

  // Task
  tasks: "tasks",
  taskCreate: "task_create",
  taskUpdate: "task_update",
  taskDelete: "task_delete",

  // Settings payment method
  paymentMethods: "payment_methods",
  paymentMethodCreate: "payment_method_create",
  paymentMethodUpdate: "payment_method_update",
  paymentMethodDelete: "payment_method_delete",
  // Settings detail
  receiptDetailGet: "receipt_detail_get",
  receiptDetailSet: "receipt_detail_set",
  // Settings devices
  devices: "devices",
  deviceCreate: "device_create",
  deviceUpdate: "device_update",
  deviceDelete: "device_delete",
};
