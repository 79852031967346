import { useSelector } from "react-redux";

import Converter from "services/converter";
import CurrencyService from "services/currency";

export default function useCashBalance() {
  const currencies = useSelector(
    (state) => state.get_All_Finance_Currency_Reducer.data.data
  );
  const [mainCurrency, findCurrency] = CurrencyService();
  const [convert] = Converter();
  function calculateCashSum(balance, no_is_refund) {
    if (balance?.length) {
      let sum = balance.reduce(function (total, currentValue) {
        if (no_is_refund && currentValue.is_refund) {
          return (
            total -
            convert(
              currentValue.amount,
              findCurrency(currentValue.currency_id)?._id,
              mainCurrency?._id
            )
          );
        }
        return (
          total +
          convert(
            currentValue.amount,
            findCurrency(currentValue.currency_id)._id,
            mainCurrency?._id
          )
        );
      }, 0);
      return sum;
    }
    return 0;
  }

  return [calculateCashSum, currencies?.find((itm) => itm?.is_main)];
}
