import toast from "react-hot-toast"
import {create_HR_Section} from "../../../store/hr/section/create"
import {Success} from "../../../api";

const create_HR_Section_Reducer = (state = create_HR_Section, action) => {
    switch (action.type) {
        case "CREATE_HR_SECTION_0" :
            return Success("/hr/sections", "Successfully created!", action.payload.history);
        case "CREATE_HR_SECTION_13000" :
            return toast.error(action.payload)
        case "CREATE_HR_SECTION_14003" :
            return toast.error(action.payload)
        case "CREATE_HR_SECTION_14002" :
            return toast.error(action.payload)
        case "CREATE_HR_SECTION_14001" :
            return toast.error(action.payload)
        case "CREATE_HR_SECTION_10000" :
            return toast.error(action.payload)
        case "CREATE_HR_SECTION_10001" :
            return toast.error(action.payload)
        case "CREATE_HR_SECTION_11000" :
            return toast.error(action.payload)
        default :
            return state
    }
}
export default create_HR_Section_Reducer