import { get_Page_Limit } from "../../../store/sozlamalar/customization/page_limit";
import { toast } from "react-hot-toast";

const get_Page_Limit_Reducer = (state = get_Page_Limit, action) => {
  switch (action.type) {
    case "GET_PAGE_LIMIT_0":
      return {
        page_limit: action.payload.data,
      };
    case "GET_PAGE_LIMIT_10000":
      toast.error("Unknown error to load all data");
      break;
    default:
      return state;
  }
};
export default get_Page_Limit_Reducer;
