import { useQueryParams } from "hooks";
import moment from "moment";
import get from "lodash.get";

export default function useFilter() {
  const { setParam } = useQueryParams();

  const selectChangeHandler = (event) => {
    const { name, value } = get(event, "target", {});
    if (value !== undefined && value !== "all") {
      setParam(name, value);
    } else {
      setParam(name, undefined);
    }
  };

  const dateRangePickerCloseHandler = (dates = []) => {
    dates.map((date) =>
      setParam(
        date.name,
        date.date
          ? ["from_date", "date_from"].includes(date.name)
            ? moment(date.date).startOf("day").toISOString()
            : moment(date.date).endOf("day").toISOString()
          : undefined
      )
    );
  };

  const debounceInputChangeHandler = (event) => {
    const { name, value } = get(event, "target", {});
    setParam(name, value);
  };

  const multiSelectChangeHandler = (options = [], name, itemValueKey) => {
    const optionValues = options.reduce(
      (res, option) => [...res, option[itemValueKey]],
      []
    );
    setParam(name, options?.length ? optionValues : undefined);
  };

  const timeRangePickerChangeHandler = (times) => {
    if (times.every((time) => time.time === 0)) {
      setParam("custom", false);
    } else {
      setParam("custom", true);
    }
    times.map((time) => setParam(time.name, time.time));
  };

  return {
    selectChangeHandler,
    dateRangePickerCloseHandler,
    debounceInputChangeHandler,
    multiSelectChangeHandler,
    timeRangePickerChangeHandler,
  };
}
