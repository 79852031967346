import error_message_handler from "services/error_message_handler";
import { postAxios } from "../../../api";

export const receive_Products_Order_Action =
  (state, history) => async (dispatch) => {
    try {
      await postAxios(`/back-api/admin/product/order/receive`, state)
        .then((response) => {
          return dispatch({
            type: "RECEIVE_PRODUCTS_ORDER_0",
            payload: { response, history },
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace("/login");
          } else {
            error_message_handler(error.response.data.code);
          }
          if (error.response) {
            const code = error.response.data.code;
            switch (code) {
              case 31003:
                dispatch({
                  type: "RECEIVE_PRODUCTS_ORDER_31003",
                  payload: error.message.data.message,
                });
                break;
              case 10001:
                dispatch({
                  type: "RECEIVE_PRODUCTS_ORDER_10001",
                  payload: error.response.data.message,
                });
                break;
              case 10000:
                dispatch({
                  type: "RECEIVE_PRODUCTS_ORDER_10000",
                  payload: error.response.data.message,
                });
                break;
              default:
                return error;
            }
          }
        });
    } catch (err) {}
  };
