import { useContext, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, CircularProgress, Paper, ThemeProvider } from "@mui/material";

import "./sass/app.css";
import Comps from "./pages";
import { getToken } from "./Token";
import { GlobalContext } from "./context";
import { onMessageListener } from "./firebaseInit";
import { WindowLoader } from "./components/common";
import { Notifications } from "./components/common";
import NotificationServices from "./services/notificationServices/container";

function App() {
  const [, setShow] = useState(false);
  const [notificationAccess] = NotificationServices();

  const {
    state: { muiTheme, screenLoader },
    actions: { setChangingTableData },
  } = useContext(GlobalContext);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      payload.data.data = JSON.parse(payload?.data?.data);
      console.log("🚀 ~ file: App.js ~ line 27 ~ .then ~ payload", payload);
      setChangingTableData(payload);
      notificationAccess(payload.data.data);
    })
    .catch((err) => ({}));

  if (getToken() && screenLoader)
    return (
      <Paper>
        <Box sx={{ display: "flex", height: "100vh" }}>
          <CircularProgress style={{ margin: "auto" }} />
        </Box>
      </Paper>
    );

  return (
    <Router>
      <ThemeProvider theme={muiTheme}>
        <Comps />
        <div style={{ display: "none" }} id={"generalLoader"}>
          <WindowLoader w100 />
        </div>
        {/* <Toaster
        position="top-right"
        reverseOrder={false}
      /> */}
        <Notifications />
      </ThemeProvider>
    </Router>
  );
}

export default App;
