import { useEffect, useState, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import {
  Box,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { AccountCircle, Close, Menu } from "@mui/icons-material";
import SignalWifiConnectedNoInternet4RoundedIcon from "@mui/icons-material/SignalWifiConnectedNoInternet4Rounded";
import DevicesIcon from "@mui/icons-material/Devices";

import CustomSelect from "components/custom_select";
import { Confirmation } from "components/common";
import { universal } from "services/api";
import { useNetwork } from "hooks";
import { IndexedDB } from "utils";
import { GlobalContext } from "context";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";
import { LOCAL_STORAGE_CONST } from "constants/localStorage.constants";

import {
  StyledAlert,
  StyledAppBar,
  StyledIconButtonBlock,
  StyledSwitchOfflineBox,
} from "./style/AppBar.style";
import ChoseDeviceDialog from "./choseDeviceDialog";

const options = [
  { value: "uz", label: "uz" },
  { value: "ru", label: "ru" },
  { value: "en", label: "en" },
];

const Header = ({
  handleDrawer,
  handleDrawerToggled,
  divRef,
  open,
  toggled,
  LazyLoadImage,
}) => {
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();
  const [language, setLanguage] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const { online } = useNetwork();

  const {
    state: { isOpenChoseDevice },
    actions: { setIsOpenChoseDevice },
  } = useContext(GlobalContext);

  const commonDB = useMemo(
    () =>
      new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      }),
    []
  );

  const logOut = async () => {
    await universal.client("/back-api/admin/logout", {
      device_id: selectedDevice?.device_id,
    });
    Object.keys(localStorage)?.forEach(
      (key) =>
        ![LOCAL_STORAGE_CONST.imei].includes(key) &&
        localStorage.removeItem(key)
    );
    await window.indexedDB.databases().then((deletingDB) => {
      deletingDB.forEach((eachDb) =>
        window.indexedDB.deleteDatabase(eachDb.name)
      );
    });
    window.location.href = "/";
  };

  const history = useHistory();

  const changeLanguage = (language) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  const handleEditLanguage = (e) => {
    changeLanguage(e.target.value);
  };

  const getDeviceFromDb = async () => {
    await commonDB.connect();
    const device = await commonDB.getCollection("Session").getById("device");
    setSelectedDevice(device);
    if (pathname === "/pos/choose-organization" && !device) {
      setIsOpenChoseDevice(true);
    }
    return device;
  };

  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    setLanguage(language);
    if (language) {
      i18n.changeLanguage(language);
    }
  }, []);

  useEffect(() => {
    getDeviceFromDb();
  }, [pathname]);

  return (
    <StyledAppBar position="static">
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={1}>
            <StyledIconButtonBlock>
              <IconButton
                className="md"
                onClick={handleDrawer}
                ref={divRef}
                color="inherit"
                edge="start"
                aria-label="menu"
                size="large"
              >
                {open ? <Close /> : <Menu />}
              </IconButton>
              <IconButton
                className="sm"
                onClick={handleDrawerToggled}
                ref={divRef}
                color="inherit"
                edge="start"
                aria-label="menu"
                size="large"
              >
                {toggled ? <Close /> : <Menu />}
              </IconButton>
            </StyledIconButtonBlock>
          </Grid>
          <Grid item xs={11}>
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <Slide direction="down" in={!online}>
                  <StyledAlert variant="filled" severity="warning">
                    <SignalWifiConnectedNoInternet4RoundedIcon />
                    <Typography>{t("COMMON.YOU_ARE_OFFLINE")}</Typography>
                  </StyledAlert>
                </Slide>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2} display="flex" justifyContent="end">
                  {pathname?.startsWith("/pos") && (
                    <Grid
                      onClick={() => setIsOpenChoseDevice(true)}
                      item
                      xs="auto"
                    >
                      <StyledSwitchOfflineBox
                        variant="filled"
                        severity="warning"
                      >
                        {selectedDevice ? (
                          <DevicesIcon color="#313649" />
                        ) : (
                          <SignalWifiConnectedNoInternet4RoundedIcon />
                        )}
                        <Typography sx={{ marginLeft: "10px" }}>
                          {selectedDevice
                            ? selectedDevice?.device_name
                            : t("COMMON.SWITCH_OFFLINE")}
                        </Typography>
                      </StyledSwitchOfflineBox>
                    </Grid>
                  )}
                  <Grid item xs="auto">
                    <CustomSelect
                      value={language}
                      style={{ color: "#000" }}
                      name="lang"
                      optionAllView={false}
                      onChange={handleEditLanguage}
                    >
                      {options.map((item) => (
                        <option
                          style={{
                            color: "#000",
                            display: item.value === language && "none",
                          }}
                          key={item.value}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid item xs="auto">
                    <Box
                      textAlign={"center"}
                      display={"flex"}
                      color="#313649"
                      pl={0.3}
                      pr={2}
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 26,
                        cursor: "pointer",
                        height: "40px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#313649",
                      }}
                      onClick={() => history.push("/profile")}
                    >
                      <AccountCircle color="#313649" fontSize="large" />

                      <Typography style={{ padding: "4px", color: "#313649" }}>
                        {
                          JSON.parse(localStorage.getItem("userData"))
                            ?.full_name
                        }
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Confirmation
                      onConfirm={() => logOut()}
                      title={t("COMMON.do_you_really_want_logout")}
                    >
                      <IconButton
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "40px",
                          height: "40px",
                        }}
                        size="large"
                      >
                        <LazyLoadImage
                          src="/images/logOut.svg"
                          alt="sadfds"
                          style={{ width: "25px", height: "25px" }}
                        />
                      </IconButton>
                    </Confirmation>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ChoseDeviceDialog
          isOpenChoseDevice={isOpenChoseDevice}
          setIsOpenChoseDevice={setIsOpenChoseDevice}
          getDeviceFromDb={getDeviceFromDb}
        />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
