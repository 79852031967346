import get from "lodash.get";

import { IndexedDB, isExistDb } from "utils";
import { LOCAL_STORAGE_CONST } from "constants/localStorage.constants";
import { COMMON_DB, COMMON_DB_COLLECTIONS } from "constants/common";
import PosDB from "pages/pos/utils/PosDB/PosDB";
import { posUniversal } from "services/api";

export const useProductsChange = () => {
  const upsertProducts = async (notificationData) => {
    const isExistTheNamedDb = await isExistDb(
      notificationData?.organization_id
    );
    const editProductDb = async (organization_id) => {
      const DB = new PosDB(organization_id);
      const organizationDataInLocalStorage = JSON.parse(
        localStorage.getItem(
          `${LOCAL_STORAGE_CONST.organization}_${organization_id}`
        )
      );
      const {
        data: { total },
      } = await posUniversal.client("/back-api/admin/product/pos/get-paging", {
        limit: 1,
        page: 1,
        organization_id,
        updated_at: get(
          organizationDataInLocalStorage,
          LOCAL_STORAGE_CONST.productUpdatedAt
        ),
      });
      const {
        data: { data },
        time,
      } =
        total &&
        (await posUniversal.client("/back-api/admin/product/pos/get-paging", {
          limit: total + 1,
          page: 1,
          organization_id,
          updated_at: get(
            organizationDataInLocalStorage,
            LOCAL_STORAGE_CONST.productUpdatedAt
          ),
        }));
      if (data?.length) {
        localStorage.setItem(
          `${LOCAL_STORAGE_CONST.organization}_${organization_id}`,
          JSON.stringify({
            ...organizationDataInLocalStorage,
            [LOCAL_STORAGE_CONST.productUpdatedAt]: time,
          })
        );
        DB.connect().then((apis) => {
          apis.editProducts(data);
        });
      }
    };
    if (!notificationData?.organization_id) {
      const commonDb = new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      });
      await commonDb.connect();
      const organizationsInSessionDb = await commonDb
        .getCollection("Session")
        .getById("organizations");
      organizationsInSessionDb?.data?.map((organization_id) =>
        editProductDb(organization_id)
      );
    }
    if (isExistTheNamedDb) {
      editProductDb(notificationData?.organization_id);
    }
  };

  const deleteProducts = async (notificationData) => {
    const isExistTheNamedDb = await isExistDb(
      notificationData?.organization_id
    );
    const deleteProductInDb = async (organization_id) => {
      const DB = new PosDB(organization_id);
      const organizationDataInLocalStorage = JSON.parse(
        localStorage.getItem(
          `${LOCAL_STORAGE_CONST.organization}_${organization_id}`
        )
      );
      const { data, time } = await posUniversal.client(
        "/back-api/admin/product/get-deleted",
        {
          organization_id,
          deleted_at: get(
            organizationDataInLocalStorage,
            LOCAL_STORAGE_CONST.productDeletedAt
          ),
        }
      );

      if (data?.length) {
        localStorage.setItem(
          `${LOCAL_STORAGE_CONST.organization}_${organization_id}`,
          JSON.stringify({
            ...organizationDataInLocalStorage,
            [LOCAL_STORAGE_CONST.productDeletedAt]: time,
          })
        );
        DB.connect().then(async (apis) => apis.deleteProducts(data));
      }
    };
    if (!notificationData?.organization_id) {
      const commonDb = new IndexedDB(COMMON_DB, {
        collections: COMMON_DB_COLLECTIONS,
      });
      await commonDb.connect();
      const organizationsInSessionDb = await commonDb
        .getCollection("Session")
        .getById("organizations");
      organizationsInSessionDb?.data?.map((organization_id) =>
        deleteProductInDb(organization_id)
      );
    }
    if (isExistTheNamedDb) {
      deleteProductInDb(notificationData?.organization_id);
    }
  };

  return { upsertProducts, deleteProducts };
};
