import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Box, TextField, IconButton } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDateRangePicker from "@mui/lab/DesktopDateRangePicker";
import { StyledFormField } from "../style/Form.style";
import { Close } from "@mui/icons-material";

export default function DateRangePicker({
  onClose,
  fromDateName = "from",
  toDateName = "to",
  defaultValues = [null, null],
  clearable = false,
  inputFormat = "dd/MM/yyyy",
  ...props
}) {
  const [dates, setDates] = useState(defaultValues);
  const { t } = useTranslation();

  const closeHandler = () => {
    if (onClose && dates[0] && dates[1]) {
      onClose([
        { name: fromDateName, date: dates[0] },
        { name: toDateName, date: dates[1] },
      ]);
    }
  };

  const renderDate = ({ label, ...props }) =>
    !clearable ? (
      <StyledFormField>
        <TextField label={t(label)} {...props} />
      </StyledFormField>
    ) : (
      <TextField label={t(label)} {...props} />
    );

  const renderDesktopDateRangePicker = () => (
    <DesktopDateRangePicker
      value={dates}
      inputFormat={inputFormat}
      onClose={closeHandler}
      onChange={setDates}
      {...props}
      renderInput={(startProps, endProps) => (
        <Fragment>
          {renderDate(startProps)}
          <Box sx={{ mr: 1 }}></Box>
          {renderDate(endProps)}
        </Fragment>
      )}
    />
  );

  const renderClearable = () => (
    <IconButton
      onClick={() => {
        onClose([
          { name: fromDateName, date: undefined },
          { name: toDateName, date: undefined },
        ]);
        setDates([null, null]);
      }}
      sx={{
        width: "43px",
      }}
    >
      <Close />
    </IconButton>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        {!clearable ? (
          renderDesktopDateRangePicker()
        ) : (
          <StyledFormField>
            {renderDesktopDateRangePicker()}
            {clearable && (!!dates[0] || !!dates[1]) && renderClearable()}
          </StyledFormField>
        )}
      </Stack>
    </LocalizationProvider>
  );
}
