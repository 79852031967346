import { OrganizationsSwitchProvider } from "../context";
import OrganizationsSwitch from "./OrganizationsSwitch";

const Index = (props) => {
  return (
    <OrganizationsSwitchProvider>
      <OrganizationsSwitch {...props} />
    </OrganizationsSwitchProvider>
  );
};

export default Index;
