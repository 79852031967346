import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Pagination,
  Typography,
} from "@mui/material";

import { REQUEST_STATUSES, useRequest } from "hooks";

import {
  BoxButtonWrapperStyle,
  BoxContainerStyle,
  CancelButton,
  SaveButton,
  StyledDialogContent,
} from "./AutoCompleteDialog.style";

const AutoCompleteDialog = (props) => {
  const { t } = useTranslation();
  const { url, payload, label, onSubmit, submitStatus, onFocus } = props;
  const [choseItemStore, setChoseItemStore] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [getDataByChooseClient, getDataByChooseData] = useRequest();

  const getDataByChoose = (page) => {
    setIsOpenDialog(true);
    getDataByChooseClient.post(url, { limit: 10, page, ...payload });
  };

  const renderLabel = () => (
    <Typography
      onFocus={onFocus}
      onClick={() => getDataByChoose(1)}
      color="primary"
      variant="subtitle1"
      sx={{ fontWeight: "bold", cursor: "pointer" }}
    >
      {label}
    </Typography>
  );

  const onChangeChoseStore = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setChoseItemStore((prevState) => [...prevState, id]);
    } else {
      setChoseItemStore((prevState) => prevState.filter((itm) => itm !== id));
    }
  };

  useEffect(() => {
    if (submitStatus === REQUEST_STATUSES.success) {
      setIsOpenDialog(false);
    }
  }, [submitStatus]);

  const renderDialog = () => (
    <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
      <DialogTitle>{label}</DialogTitle>
      <StyledDialogContent>
        {getDataByChooseData?.data?.map((chooseItem) => (
          <FormControlLabel
            style={{ marginRight: 20 }}
            control={
              <Checkbox
                id={chooseItem?._id}
                value={chooseItem?._id}
                icon={<CheckBoxOutlineBlank fontSize="medium" />}
                checked={choseItemStore?.includes(chooseItem?._id)}
                checkedIcon={<CheckBox fontSize="medium" />}
                onChange={onChangeChoseStore}
                name="checked"
                color="primary"
              />
            }
            label={chooseItem?.name}
          />
        ))}
        {renderPagination()}
      </StyledDialogContent>
      <DialogActions>
        <BoxContainerStyle>
          <BoxButtonWrapperStyle>
            <CancelButton
              variant="contained"
              onClick={() => setIsOpenDialog(false)}
              color="white"
              className={"radius-12"}
            >
              {t("COMMON.CANCEL")}
            </CancelButton>
            <SaveButton
              className="radius-12"
              variant="contained"
              color="primary"
              onClick={() => onSubmit(choseItemStore)}
            >
              {t("COMMON.SAVE")}
            </SaveButton>
          </BoxButtonWrapperStyle>
        </BoxContainerStyle>
      </DialogActions>
    </Dialog>
  );

  const renderPagination = () => (
    <Pagination
      count={Math.ceil(getDataByChooseData?.total / 10)}
      color="primary"
      onChange={(e, v) => getDataByChoose(v)}
    />
  );

  return (
    <Box>
      {renderLabel()}
      {renderDialog()}
    </Box>
  );
};

export default AutoCompleteDialog;
