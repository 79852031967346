import { useContext, useEffect, useMemo } from "react";
import { Grid, IconButton, TableCell } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

import { ControlledSelect } from "components/form";

import Table from "../../Table/Table";
import ProductListTableContext from "../context/ProductListTableContext";

const ProductListTable = ({
  request,
  payload,
  renderItemProps,
  defaultList = [],
  disabledSelect = false,
  name = "productSelect",
  itemLabelKey = "name",
  getOptionLabel = (option) => option.name,
  ...props
}) => {
  const {
    state: { list, data },
    actions: {
      deleteRowItem,
      addRowItem,
      fetchProductsList,
      tableCellChangeHandler,
      setList,
    },
  } = useContext(ProductListTableContext);

  const columns = useMemo(
    () => [
      ...props.columns,
      {
        renderComponent: (data) => (
          <TableCell>
            <IconButton>
              <DeleteOutline onClick={() => deleteRowItem(data)} />
            </IconButton>
          </TableCell>
        ),
        style: { width: "10px" },
      },
    ],
    [deleteRowItem, props.columns]
  );

  useEffect(() => {
    defaultList.length && setList(defaultList);
  }, []);

  const onProductSelectChange = (product) => {
    renderItemProps.clearErrors(name);
    if (!props.onProductSelectChange) {
      return addRowItem(product);
    }
    const editedProduct = props.onProductSelectChange(product);
    if (editedProduct) {
      return addRowItem(editedProduct);
    }
    addRowItem(product);
  };

  useEffect(() => {
    const watch = request?.watch || [];
    const hasAllRequiredData = watch.reduce(
      (res, item) => (item ? res : false),
      true
    );
    hasAllRequiredData && fetchProductsList(request.url, request.payload);
  }, [...request?.watch]);

  const renderTable = useMemo(
    () => (
      <Table
        columns={columns}
        data={list}
        renderItemProps={{
          ...renderItemProps,
          onChange: tableCellChangeHandler,
        }}
        emptydatamessage={props?.emptydatamessage}
      />
    ),
    [list, tableCellChangeHandler, renderItemProps, columns]
  );

  const renderInput = () => (
    <ControlledSelect
      itemLabelKey={itemLabelKey}
      name={name}
      disabled={disabledSelect}
      control={renderItemProps?.control}
      errors={renderItemProps?.formState?.errors}
      rules={renderItemProps?.rules}
      getOptionLabel={getOptionLabel}
      options={data?.data || []}
      onChange={onProductSelectChange}
      onInputChange={(event) =>
        fetchProductsList(request.url, {
          search: event?.target?.value,
          ...request.payload,
          ...payload,
        })
      }
      {...props}
    />
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderTable}
      </Grid>
      <Grid item xs={12}>
        {request && renderInput()}
      </Grid>
    </Grid>
  );
};

export default ProductListTable;
