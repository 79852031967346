import SettingsApi from "./modules/settings";
import TasksApi from "./modules/tasks";
import Auth from "./modules/auth";
import HrApi from "./modules/hr";
import FileApi, { NewFileApi } from "./modules/upload";
import SupplementApi from "./modules/supplement.js";
import CounterApi from "./counter";
import TaskAttachment from "./modules/taskAttachment";
import { SaleApi } from "./modules/sale";
import WarehouseApi from "./modules/warehouse";
import ProductsApi from "./modules/products";
import ProductCategoriesApi from "./modules/productCategory";
import MaterialApi from "./modules/material";
import { CashApi } from "./modules/cash";
import CustomerApi from "./modules/customer";
import { InventoryApi } from "./modules/inventory";
import CrossSellApi from "./modules/cross-sell";
import PosApi from "./modules/pos";
import { Universal } from "./modules/universal";
import ProductionTemplateApi from "./modules/production-template";
import ProductionApi from "./modules/production";
import ProductionOrderApi from "./modules/productionOrder";
import ProductionPlanApi from "./modules/productionPlan";
import ReportApi from "./modules/report";
import { PosUniversal } from "./modules/posUniversal";

const settingsApi = new SettingsApi();
const tasksApi = new TasksApi();
const authApi = new Auth();
const hrApi = new HrApi();
const fileApi = new FileApi();
const newFileApi = new NewFileApi();
const supplementApi = new SupplementApi();
const counterApi = new CounterApi();
const taskAttachment = new TaskAttachment();
const saleApi = new SaleApi();
const warehouseApi = new WarehouseApi();
const productsApi = new ProductsApi();
const productCategoriesApi = new ProductCategoriesApi();
const materialApi = new MaterialApi();
const cashApi = new CashApi();
const customerApi = new CustomerApi();
const inventoryApi = new InventoryApi();
const crossSellApi = new CrossSellApi();
const posApi = new PosApi();
const posUniversal = new PosUniversal();
const universal = new Universal();
const productionTemplateApi = new ProductionTemplateApi(
  process.env.REACT_APP_BASE_PRODUCTION_URL
);
const productionApi = new ProductionApi(
  process.env.REACT_APP_BASE_PRODUCTION_URL
);
const productionOrderApi = new ProductionOrderApi(
  process.env.REACT_APP_BASE_PRODUCTION_URL
);
const productionPlanApi = new ProductionPlanApi(
  process.env.REACT_APP_BASE_PRODUCTION_URL
);
const reportApi = new ReportApi(process.env.REACT_APP_BASE_REPORT_URL, "");

export {
  posApi,
  inventoryApi,
  customerApi,
  cashApi,
  settingsApi,
  tasksApi,
  authApi,
  hrApi,
  fileApi,
  supplementApi,
  counterApi,
  taskAttachment,
  saleApi,
  warehouseApi,
  productsApi,
  productCategoriesApi,
  materialApi,
  crossSellApi,
  universal,
  productionApi,
  productionTemplateApi,
  productionOrderApi,
  productionPlanApi,
  reportApi,
  newFileApi,
  posUniversal,
};
