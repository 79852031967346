import { Api } from "../api";

export class CashApi extends Api {
  addCash(data) {
    return this.execute("post", "/back-api/admin/finance/cashbox/create", data);
  }

  updateCash(data) {
    return this.execute("post", "/back-api/admin/finance/cashbox/update", data);
  }

  deleteCash(data) {
    return this.execute("post", "/back-api/admin/finance/cashbox/delete", data);
  }

  getPagingCash(data) {
    return this.execute("post", "back-api/admin/finance/cashbox/get-all", data);
  }

  getPaymentType(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/payment-type/get-all",
      data
    );
  }

  addTransaction(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/transaction/create",
      data
    );
  }

  getTransaction(data) {
    return this.execute(
      "post",
      "/back-api/admin/finance/transaction/get-paging",
      data
    );
  }
}
