import { Suspense } from "react";
import ReactDOM from "react-dom";
import { CircularProgress } from "@mui/material";
import "moment/locale/uz-latn";
import { Provider } from "react-redux";

import App from "./App";
import store from "./redux/reducers";
import "./i18n";
import "./sass/style.css";
import { GlobalProvider } from "context";

ReactDOM.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
