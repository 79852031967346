import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { StyledButton, StyledTypography } from "./DeleteButton.style";

const DeleteButton = ({ children, icon, textColor = "#fff", ...props }) => {
  const { t } = useTranslation();

  return (
    <StyledButton size="large" color="error" variant="contained" {...props}>
      {icon || <DeleteIcon color={textColor} fontSize="small" />}
      <StyledTypography color={textColor}>
        {children || t("COMMON.DELETE")}
      </StyledTypography>
    </StyledButton>
  );
};

export default DeleteButton;
