import { Api } from "../api";

class PosApi extends Api {
  addPos(data) {
    return this.execute("post", "/back-api/admin/pos/receipt/create", data);
  }

  getPosses(data) {
    return this.execute("post", "/back-api/admin/pos/receipt/get-paging", data);
  }
}

export default PosApi;
