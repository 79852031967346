import axios from "axios";
import toast from "react-hot-toast";
import { getToken } from "../../Token";

/**
 * Core API, takes care request and response
 */
class UploadFileApi {
  constructor() {
    this.api2client = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_UPLOAD_URL}/public`,
    });
    this.api2client.defaults.params = {};
    this.query = {};
    this.token = getToken();
    this.setInterceptors();
  }

  setInterceptors() {
    this.api2client.interceptors.response.use(
      (resp) => {
        if (resp.data) {
          if (typeof resp.data === "string") {
            resp.data = {
              data: resp.data,
              status: resp.status,
            };
          } else {
            resp.data.status = resp.status;
          }
        }
        return resp.data;
      },
      (err) => {
        throw err;
      }
    );
  }

  async execute(method, url, data, query, onUploadProgress) {
    const accessToken = getToken();
    let headers = {};
    if (accessToken) {
      headers = {
        Authorization: accessToken,
      };
    }
    try {
      const resp = await this.api2client({
        method,
        url,
        data,
        headers,
        onUploadProgress,
      });

      if (url.endsWith("update") || url.endsWith("set")) {
        toast.success("Successful updated");
      } else if (url.endsWith("create")) {
        toast.success("Successful created");
      } else if (url.includes("delete")) {
        toast.success("Successful deleted");
      }

      return resp.data;
    } catch (err) {
      const error = err.response;
      if (!error) {
        toast.error("Internet connection error!");
        throw err;
      } else throw err.response.data;
    }
  }

  existToken() {
    return this.token.exist();
  }

  clearToken() {
    this.token.clear();
  }

  /**
   * common api
   */
}

export { UploadFileApi };
