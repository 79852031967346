import BoardContext from "./BoardContext";
import useBoard from "./useBoard";

const BoardProvider = ({ children, propsData }) => {
  const store = useBoard(propsData);

  return (
    <BoardContext.Provider value={{ ...store, props: propsData }}>
      {children}
    </BoardContext.Provider>
  );
};

export default BoardProvider;
