import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { debounce } from "services/debounce";

import useOutsideHandler from "../../hooks/useOutsideHandler";
import chevron from "./chevron.svg";
import "./style.css";

const CustomSelect = ({
  optionAllView = true,
  formView = false,
  onSearch,
  searchable = false,
  label,
  children,
  className,
  name,
  style = null,
  value,
  onChange = console.log,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const { t } = useTranslation();
  const outsideOfSelectRef = useRef();
  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  useOutsideHandler(outsideOfSelectRef, setIsOpen);

  const handleChange = (value) => {
    let payload = {
      target: {
        name: name,
        value: value,
      },
    };
    onChange(payload);
    setIsOpen(false);
  };

  const renderChildren = () => {
    return React.Children.map(children, (child, i) => {
      return (
        child &&
        React.cloneElement(child, {
          onClick: () => handleChange(child.props.value),
          className: `list-item ${
            child?.props?.value === selectedOption?.value ? "active" : ""
          }`,
        })
      );
    });
  };
  useEffect(() => {
    const childArray = React.Children.toArray(children);
    for (const child of childArray) {
      if (child.props.value === value) {
        return setSelectedOption({
          value: child.props.value,
          name: child.props.children,
        });
      }
    }
    setSelectedOption(undefined);
  }, [value]);

  const handleSearch = (event) => {
    onSearch(event);
  };

  const debounceSearch = useCallback(debounce(handleSearch, 300), []);

  return (
    <div
      style={style}
      className={`custom-input ${formView ? "form-view" : ""} ${className}`}
    >
      {label && <div className={"select-label"}>{label}</div>}
      <div className={`main`}>
        <div ref={outsideOfSelectRef} className={"drop-down-container"}>
          <div className={"drop-down-header"} onClick={toggling}>
            {selectedOption?.name ||
              `${optionAllView ? t("ENUMS.ALL") : t("COMMON.NOT_SELECTED")}`}
            <img
              className={`chevron ${isOpen ? "chevron-rotate" : ""}`}
              src={chevron}
              alt=""
            />
          </div>
          {isOpen && (
            <div className={"drop-down-list-container"}>
              {" "}
              {searchable && (
                <div style={{ textAlign: "center" }}>
                  <input
                    onChange={debounceSearch}
                    className={"search-option"}
                    type="text"
                    placeholder={t("COMMON.SEARCH")}
                  />
                </div>
              )}
              <div className={"drop-down-list"}>
                {optionAllView && (
                  <option
                    onClick={() => handleChange(undefined)}
                    className={`list-item ${selectedOption ? "" : "active"}`}
                    value={true}
                  >
                    {t("ENUMS.ALL")}
                  </option>
                )}
                {renderChildren()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
