export const errorCodeName = {
  uz: {
    0: "Muvaffaqiyat",
    10000: "Standart",
    11000: "Foydalanuvchi",
    11001: "Foydalanuvchi",
    12000: "Moderator",
    13000: "Tashkilot",
    14000: "Bo'lim",
    15000: "Jadval",
    16000: "Position",
    17000: "Qurilma",
    18000: "To'lov usuli",
    19000: "Valyuta",
    20000: "Soliq",
    21000: "Ta'minatchi",
    22000: "Faol",
    23000: "Material toifasi",
    24000: "Material",
    25000: "Mahsulot toifasi",
    26000: "Mahsulot",
    27000: "Mahsulot daromadi",
    28000: "Mahsulot daromadlari",
    29000: "Mahsulot o'tkazish",
    30000: "Mahsulotni o'tkazish",
    31000: "Mahsulotga buyurtma",
    32000: "Mahsulot buyurtmalari",
    33000: "Mahsulotni qaytarish",
    34000: "Mahsulotni qaytarish",
    35000: "Moddiy daromad",
    36000: "Moddiy daromad ob'ektlari",
    37000: "Material o'tkazish",
    3800: "Material o'tkazishi buyumlari",
    39000: "Moddiy buyurtma",
    40000: "Moddiy buyurtma buyumlari",
    41000: "Material qaytish",
    42000: "Material qaytish buyumlari",
    43000: "Mijoz",
    44000: "Chegirma",
    45000: "Kross sell",
    46000: "O'zaro savdo ob'ektlari",
    47000: "Sotish taklif",
    48000: "Sotish takliflari",
    49000: "Ishlab chiqarish rejasi",
    50000: "Ishlab chiqarish jarayoni",
    51000: "Ishlab chiqarish jarayoni bloklari",
    52000: "Vazifa",
    53000: "Tashroq ro'yxati",
    54000: "Subtask",
    55000: "Ilova",
    56000: "Sotish buyurtmasi",
    57000: "Sotish buyurumlari",
    58000: "Sotish shartnomasi",
    59000: "Sotish shartnomalari",
    60000: "Cashbox",
    61000: "Tranzaksiya",
    62000: "To'lov turi",
    63000: "Rol",
    64000: "Inventoriy ro'yxatlari",
    65000: "Inventoriya soni obyu",
    66000: "Kabitta",
    67000: "Qabul qilgan",
    68000: "Printer",
    69000: "Ishlab chiqarish jarayoni liniyalari",
    70000: "Ishlab chiqarish jarayoni materiallari",
    71000: "Ishlab chiqarish jarayonini o'tkazish",
    72000: "Ishlab chiqarish jarayonini o'tkazish ob'ektlari",
    73000: "Izoh",
    74000: "Ishlab chiqarish buyurtmasi",
    75000: "Ishlab chiqarish buyurtmalari",
    76000: "tovar ishlab chiqarish rejasida",
    77000: "Ishlab chiqarilgan tarix",
    78000: "Pos page",
    79000: "Pos-sahifa ehmlari",
  },
  en: {
    0: "SUCCESS",
    10000: "DEFAULT",
    11000: "USER",
    12000: "MODERATOR",
    13000: "ORGANIZATION",
    14000: "SECTION",
    15000: "SCHEDULE",
    16000: "POSITION",
    17000: "DEVICE",
    18000: "PAYMENT METHOD",
    19000: "CURRENCY",
    20000: "TAX",
    21000: "SUPPLIER",
    22000: "ACTIVE",
    23000: "MATERIAL CATEGORY",
    24000: "MATERIAL",
    25000: "PRODUCT CATEGORY",
    26000: "PRODUCT",
    27000: "PRODUCT INCOME",
    28000: "PRODUCT INCOME ITEMS",
    29000: "PRODUCT TRANSFER",
    30000: "PRODUCT TRANSFER ITEMS",
    31000: "PRODUCT ORDER",
    32000: "PRODUCT ORDER ITEMS",
    33000: "PRODUCT RETURN",
    34000: "PRODUCT RETURN ITEMS",
    35000: "MATERIAL INCOME",
    36000: "MATERIAL INCOME ITEMS",
    37000: "MATERIAL TRANSFER",
    3800: "MATERIAL TRANSFER ITEMS",
    39000: "MATERIAL ORDER",
    40000: "MATERIAL ORDER ITEMS",
    41000: "MATERIAL RETURN",
    42000: "MATERIAL RETURN ITEMS",
    43000: "CUSTOMER",
    44000: "DISCOUNT",
    45000: "CROSS SELL",
    46000: "CROSS SELL ITEMS",
    47000: "SALE OFFER",
    48000: "SALE OFFER ITEMS",
    49000: "PRODUCTION PLAN",
    50000: "PRODUCTION PROCESS",
    51000: "PRODUCTION PROCESS BLOCKS",
    52000: "TASK",
    53000: "CHECKLIST",
    54000: "SUBTASK",
    55000: "ATTACHMENT",
    56000: "SALE ORDER",
    57000: "SALE ORDER ITEMS",
    58000: "SALE CONTRACT",
    59000: "SALE CONTRACT ITEMS",
    60000: "CASHBOX",
    61000: "TRANSACTION",
    62000: "PAYMENT TYPE",
    63000: "ROLE",
    64000: "INVENTORY COUNTS",
    65000: "INVENTORY COUNT ITEM",
    66000: "RECEIPT",
    67000: "RECEIPT ITEM",
    68000: "PRINTER",
    69000: "PRODUCTION PROCESS LINES",
    70000: "PRODUCTION PROCESS MATERIALS",
    71000: "PRODUCTION PROCESS TRANSFER",
    72000: "PRODUCTION PROCESS TRANSFER ITEMS",
    73000: "COMMENT",
    74000: "PRODUCTION ORDER",
    75000: "PRODUCTION ORDER ITEMS",
    76000: "PRODUCTION PLAN ITEMS",
    77000: "PRODUCED HISTORY",
    78000: "POS PAGE",
    79000: "POS PAGE ITEMS",
  },
  ru: {
    0: "Успех",
    10000: "Стандарт",
    11000: "Пользователь",
    12000: "Модератор",
    13000: "Организация",
    14000: "Раздел",
    15000: "Стол",
    16000: "Позиция",
    17000: "Устройство",
    18000: "Способ оплаты",
    19000: "Валюта",
    20000: "Налог",
    21000: "Достижение",
    22000: "Активный",
    23000: "Категория материала",
    24000: "Материал",
    25000: "Категория товара",
    26000: "Товар",
    27000: "Доход от продукта",
    28000: "Доход от продукта",
    29000: "Передача продукта",
    30000: "Передача продукта",
    31000: "Заказ товара",
    32000: "Заказы на продукцию",
    33000: "Возврат товара",
    34000: "Возврат товара",
    35000: "Материальный доход",
    36000: "Статьи материального дохода",
    37000: "Передача материалов",
    3800: "Материалы для передачи материалов",
    39000: "Материальный заказ",
    40000: "Материалы заказаны",
    41000: "Возврат материала",
    42000: "Возврат материалов",
    43000: "Мийох",
    44000: "Скидка",
    45000: "Перекрестные продажи",
    46000: "Взаимные торговые соглашения",
    47000: "Предложение о продаже",
    48000: "Предложения по продаже",
    49000: "Производственный план",
    50000: "Производственный процесс",
    51000: "Производство технологических блоков",
    52000: "Долг",
    53000: "Список выхода",
    54000: "Подзадача",
    55000: "Приложение",
    56000: "Заказ на продажу",
    57000: "Заказы на продажу",
    58000: "Договор купли-продажи",
    59000: "Договоры купли-продажи",
    60000: "Касса",
    61000: "Транзакция",
    62000: "Вид платежа",
    63000: "Роль",
    64000: "Инвентарные списки",
    65000: "Инвентарный номер обью",
    66000: "Кабитта",
    67000: "Принято",
    68000: "Принтер",
    69000: "Производственные технологические линии",
    70000: "Материалы производственного процесса",
    71000: "Ведение производственного процесса",
    72000: "Производственно-технологические объекты",
    73000: "Примечание",
    74000: "Производственный заказ",
    75000: "Производственные заказы",
    76000: "Объекты производственного плана",
    77000: "Дата изготовления",
    78000: "Положительная страница",
    79000: "Почтовая страница",
  },
};

export const fieldName = {
  uz: {
    name: "nomi",
    phone_number: "telefon raqami",
    organization_id: "tashkilot",
    customer_id: "mijoz",
    order_id: "buyurtma raqam",
    source_id: "tashkilot",
    destination_id: "tashkilot",
    process_id: "jarayonni",
    destination_block_id: "blok",
    source_organization_id: "tashkilot",
    destination_organization_id: "tashkilot",
  },
};
