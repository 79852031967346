import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import CurrencyService from "services/currency";
import numberWithSpaces from "services/number_format/number_format";

const ProductTotalSums = ({
  sumList = [],
  productList = [],
  currencyKey,
  priceKey,
  isOnlyAmountCounter = false,
}) => {
  const { t } = useTranslation();
  const [mainCurrency, findCurrency] = CurrencyService();
  const { apply_in_main } = useSelector(
    (state) => state.get_Currency_Setting.data
  );

  let currencyValues = {};
  productList.length
    ? productList.forEach((item) => {
        let total = currencyValues[item[currencyKey]];
        if (!total) total = 0;
        total +=
          (item.amount || 0) *
          (typeof item[priceKey] === "string"
            ? item[priceKey]?.replaceAll(" ", "")
            : item[priceKey]);
        currencyValues[item[currencyKey]] = total;
      })
    : (currencyValues = {});
  const totalCurrencies = Object.keys(currencyValues);

  const currencyOnlyMain = totalCurrencies?.length
    ? !totalCurrencies?.find((currencyId) => currencyId !== mainCurrency?._id)
    : true;

  return (
    <Grid container my={2} spacing={2}>
      {apply_in_main && !currencyOnlyMain ? (
        <>
          <Grid item xs={6}>
            <b>{t("COMMON.TOTAL_CURRENCIES")}</b>
          </Grid>
          <Grid item xs={6}>
            {totalCurrencies.map((key) => (
              <p key={key} style={{ textAlign: "end" }}>
                {currencyValues[key] + " " + findCurrency(key)?.name}
              </p>
            ))}
          </Grid>
        </>
      ) : (
        ""
      )}
      {sumList.map(
        (list, index) =>
          list && (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <b>{t(list?.translationKey)}</b>
              </Grid>
              <Grid item xs={6} textAlign="right">
                {numberWithSpaces(list.value) + " "}
                {!isOnlyAmountCounter && mainCurrency.name}
              </Grid>
            </React.Fragment>
          )
      )}
    </Grid>
  );
};

export default ProductTotalSums;
