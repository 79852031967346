import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import { useRequest } from "hooks";

export const useProductListTable = () => {
  const {t} = useTranslation();
  const [list, setList] = useState([]);
  const [client, data, status] = useRequest();

  const deleteRowItem = useCallback(
    (item) => {
      const filteredList = list.filter((listItem) => listItem._id !== item._id);
      setList(filteredList);
    },
    [list, setList]
  );

  const addRowItem = useCallback(
    (item) => {
      if (item) {
        const itemFromList = list.find((listItem) => listItem._id === item._id);
        if (!itemFromList) {
          setList([...list, item]);
        } else {
          toast.error(t("MESSAGES.ALREADY_EXIST"))
        }
      }
    },
    [list, setList]
  );

  const fetchProductsList = (url, payload) => {
    client.post(url, {
      limit: 50,
      page: 1,
      ...payload,
    });
  };

  const tableCellChangeHandler = useCallback(
    (dataId, name, value) => {
      const editedList = list.map((item) => {
        if (item._id === dataId) {
          item[name] = value;
        }
        return item;
      });
      setList(editedList);
    },
    [list, setList]
  );

  return {
    state: {
      list,
      data,
      status,
    },
    actions: {
      setList,
      deleteRowItem,
      addRowItem,
      fetchProductsList,
      tableCellChangeHandler,
    },
  };
};
