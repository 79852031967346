import { Api } from "../api";

export class SaleApi extends Api {
  getSaleOfferPaging(data) {
    data = { ...data, sal_type: "offer" };
    return this.execute("post", "/back-api/admin/sale/get-paging", data);
  }
  refundContract(data) {
    data = { ...data, sal_type: "offer" };
    return this.execute(
      "post",
      "/back-api/admin/sale/contract/create-refund",
      data
    );
  }

  getSaleByNumber(data) {
    return this.execute("post", "/back-api/admin/sale/get-by-number", data);
  }

  getSaleOrderPaging(data) {
    data = { ...data, sal_type: "order" };
    return this.execute("post", "/back-api/admin/sale/get-paging", data);
  }

  getContractChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/contract/get-paging",
      data
    );
  }
  getContractById(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/contract/get-by-id",
      data
    );
  }

  setSaleState(data) {
    return this.execute("post", "/back-api/admin/sale/set-state", data);
  }

  setSaleContractState(data, isLoad) {
    return this.execute(
      "post",
      "/back-api/admin/sale/contract/set-state",
      data,
      isLoad
    );
  }

  getSaleOfferById(data) {
    return this.execute("post", "/back-api/admin/sale/get-by-id", data);
  }

  getSaleOrderById(data) {
    return this.execute("post", "/back-api/admin/sale/get-by-id", data);
  }

  deleteSaleById(data) {
    return this.execute("post", "/back-api/admin/sale/delete", data);
  }

  deleteSaleContractById(data) {
    return this.execute("post", "/back-api/admin/sale/contact/delete", data);
  }

  getSaleForChoose(data) {
    return this.execute("post", "/back-api/admin/sale/get-for-choose", data);
  }

  getSaleContractById(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/contract/get-by-id",
      data
    );
  }

  getSaleContractPaging(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/contract/get-paging",
      data
    );
  }

  getCrossSellById(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/cross-sell/get-by-id",
      data
    );
  }

  createCrossSell(data) {
    return this.execute("post", "/back-api/admin/sale/cross-sell/create", data);
  }

  updateCrossSell(data) {
    return this.execute("post", "/back-api/admin/sale/cross-sell/update", data);
  }

  deleteCustomerMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/customer/delete-many",
      data
    );
  }

  deleteCrossSellMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/cross-sell/delete-many",
      data
    );
  }

  deleteDiscountMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/sale/discount/delete-many",
      data
    );
  }

  deleteCustomerById(data) {
    return this.execute("post", "/back-api/admin/sale/customer/delete", data);
  }

  addClient(data) {
    return this.execute("post", "/back-api/admin/sale/customer/create", data);
  }
}
