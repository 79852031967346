import { Box, Popper } from "@mui/material";
import { styled } from "@mui/system";
import { StyledFormField } from "../style/Form.style";

export const StyledSelect = styled("select")({
  marginTop: "5px",
  padding: "3px",
  borderRadius: "3px",
  border: "1px solid #404040",
  "&:focus": {
    border: "2px solid rgb(0, 106, 255)",
    outline: "none",
  },
});

export const StyledBox = styled(Box)({
  background: "#fff !important",
  padding: "8px",
  borderRadius: 12,
  "& img": {
    position: "sticky",
    top: "12px",
  },
  "& span": {
    lineHeight: 1.5,
    color: "#000",
    fontsize: "1rem",
  },
});

export const StyledPopper = styled(Popper)({
  zIndex: 15,
  "& .hour_select_header": {
    padding: "16px",
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  "& .hour_select_body": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    "& label": {
      fontSize: "12px",
      color: "rgba(0,0,0,.56)",
    },
  },
});

export const StyledTimeRangePicker = styled(StyledFormField)({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10
})
