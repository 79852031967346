import { service_constants } from "constants";

const detectCurrencySide = (currency, value) => {
  if (currency?.side === service_constants.side.start) {
    return `${currency?.symbol || currency.name} ${value}`;
  }
  if (currency?.side === service_constants.side.end) {
    return `${value} ${currency?.symbol || currency.name}`;
  }
};

export default detectCurrencySide;
