import { styled } from "@mui/system";
import { StyledFormField } from "../style/Form.style";

export const StyledMultiSelect = styled(StyledFormField)({
  "& .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon": {
    width: "100%",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    padding: 0,
  },
  "& .MuiInputBase-formControl": {
    "& .MuiChip-root": { maxWidth: "50%" },
  },
  "& .Mui-focused": {
    "& .MuiChip-root": { maxWidth: "calc(100% - 6px)" },
  },
});
