import { UploadFileApi } from "../fileApi";
import { Api } from "../api";

class FileApi extends UploadFileApi {
  uploadFile(data) {
    const formData = new FormData();

    // Update the formData object
    formData.append("file", data, data.name);

    return this.execute("post", "/upload", formData);
  }
}
export class NewFileApi extends Api {
  uploadTransactionFileApi(data, transactionId) {
    const formData = new FormData();

    // Update the formData object
    data?.forEach((item) => {
      formData.append("file", item, item.name);
    });
    return this.execute(
      "post",
      `/back-api/admin/attachment/transaction/${transactionId}`,
      formData
    );
  }
  universalSingle(url, data, transactionId) {
    const formData = new FormData();

    // Update the formData object
    formData.append("file", data, data.name);
    return this.execute("post", `${url}/${transactionId}`, formData);
  }
  universal(url, data, id) {
    const formData = new FormData();

    // Update the formData object
    data?.forEach((item) => {
      formData.append("file", item, item.name);
    });
    return this.execute("post", `${url}/${id}`, formData, id);
  }
}

export default FileApi;
