import { styled } from "@mui/system";
import { Paper } from "@mui/material";

export const StyledFormField = styled(Paper)({
  display: "flex",
  borderRadius: 12,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none!important",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 11px) scale(1)",
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    transform: "translate(14px, -9px) scale(.75)",
  },
});
