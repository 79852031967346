import {toast} from "react-hot-toast"
import {Success} from "../../../api";
import {update_finance_currency} from "../../../store/finance/currency/update";




const update_Finance_Currency_Reducer = (state = update_finance_currency, action) => {
    switch (action.type) {
        case "UPDATE_FINANCE_CURRENCY_0":
            return Success('', 'Succefully updated', action.payload.history);
        case "UPDATE_FINANCE_CURRENCY_19001":
            toast.error(action.payload);
            break;
        case "UPDATE_FINANCE_CURRENCY_19000":
            toast.error(action.payload);
            break;
        case "UPDATE_FINANCE_CURRENCY_10001":
            toast.error(action.payload);
            break;
        case "UPDATE_FINANCE_CURRENCY_10000":
            toast.error(action.payload);
            break;
        default:
            return state;
    }
};
export default update_Finance_Currency_Reducer;