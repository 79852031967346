import { StyledCircularProgress, StyledWindowLoader } from './WindowLoader.style'

const WindowLoader = () => {
  return (
    <StyledWindowLoader>
        <StyledCircularProgress />
    </StyledWindowLoader>
  )
}

export default WindowLoader