import error_message_handler from "services/error_message_handler";
import { postAxios } from "../../../api";

export const get_ById_Warehouse_Material_Order_Action =
  (state) => async (dispatch) => {
    try {
      await postAxios(
        `/back-api/admin/warehouse/material/order/get-by-id`,
        state
      )
        .then((response) => {
          return dispatch({
            type: "GET_BY_ID_WAREHOUSE_MATERIAL_ORDER_0",
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace("/login");
          } else {
            error_message_handler(error.response.data.code);
          }
          if (error.response) {
            const code = error.response.data.code;
            switch (code) {
              case 10001:
                dispatch({
                  type: "GET_BY_ID_WAREHOUSE_MATERIAL_ORDER_10001",
                  payload: error.response.data.message,
                });
                break;
              case 10000:
                dispatch({
                  type: "GET_BY_ID_WAREHOUSE_MATERIAL_ORDER_10000",
                  payload: error.response.data.message,
                });
                break;
              default:
                return error;
            }
          }
        });
    } catch (err) {}
  };
