import { sale_setting_data } from "../../../../store/sozlamalar/user/trade/index";
import { toast } from "react-hot-toast";

const get_Page_Limit_Reducer = (state =sale_setting_data, action) => {
  switch (action.type) {
    case "GET_SALE_SETTINGS_0":
      return {
        data: action.payload.data,
      };
    case "GET_SALE_SETTINGS_10000":
      toast.error("Unknown error to load all data");
      break;
    default:
      return state;
  }
};
export default get_Page_Limit_Reducer;
