import { useContext } from "react";
import get from "lodash.get";
import { BoardContext } from "../../context";

const LaneHeader = ({ lane, provided }) => {
  const {
    actions: { deleteLane },
    props: { renderLaneHeader },
  } = useContext(BoardContext);
  return renderLaneHeader
    ? renderLaneHeader(lane, provided, deleteLane)
    : get(lane, "title", "");
};

export default LaneHeader;
