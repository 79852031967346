import {toast} from "react-hot-toast"
import {Success} from "../../api";
import {create_task} from "../../store/tasks/create";

const create_Task_Reducer = (state = create_task, action) => {
    switch (action.type) {
        case "CREATE_TASK_0" :
            return Success("/task/main", "created successfully!!!");
        case "CREATE_TASK_10001" :
            return toast.error(action.payload)
        case "CREATE_TASK_10000" :
            return toast.error(action.payload)
        default :
            return state
    }
}
export default create_Task_Reducer