import styled from "@emotion/styled";

export const ToastContainer = styled("span")({
  display: "flex",
  background: "#fff",
  boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
  padding: "10px 8px",
});

export const ToastIcon = styled("span")({
  width: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
});

export const ToastContent = styled("span")({
  width: "calc(100% - 110px)",
  height: "100%",
  display: "flex",
  alignItems: "center",
});

export const ToastClose = styled("span")({
  width: "50px",
  height: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  "button:hover": {
    backgroundColor: "rgba(234, 84, 85, 0.05)",
  },
});
