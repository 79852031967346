import RoleManager from "services/role_management";

export const useOrganizationsSwitch = () => {
  const [, getAccessedOrganizations] = RoleManager();

  const handleOrganization = (event, organizationIds, setValue, name) => {
    let { id } = event.target;
    if (organizationIds.includes(id)) {
      setValue(name, [...organizationIds.filter((org) => org !== id)], {
        shouldValidate: true,
      });
    } else {
      setValue(name, [...organizationIds, id], {
        shouldValidate: true,
      });
    }
  };

  return {
    state: {
      allOrganizations: (organizationAccessName) =>
        getAccessedOrganizations(organizationAccessName),
    },
    actions: { handleOrganization },
  };
};
