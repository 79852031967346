import { useMemo, useState } from "react";
import PosDB from "pages/pos/utils/PosDB/PosDB";
import {
  LOCAL_STORAGE_CONST,
  POS_DB_STATUS_VALUE,
} from "constants/localStorage.constants";
import { posUniversal } from "services/api";

const useSetAllPosDataToDb = (id) => {
  const [progress, setProgress] = useState(0);

  const productDb = useMemo(() => new PosDB(id), [id]);
  const categoryDb = useMemo(() => new PosDB(id), [id]);
  const customerDb = useMemo(() => new PosDB(id), [id]);
  const receiptDb = useMemo(() => new PosDB(id), [id]);
  const eachPageDataDb = useMemo(() => new PosDB(id), [id]);
  const pageDb = useMemo(() => new PosDB(id), [id]);

  const increaseStateInProgress = async () => {
    setProgress((prev) => prev + 100 / 5 / 2);
  };

  const getAllProductsForDb = async (total) => {
    const {
      data: { data },
      time,
    } = await posUniversal.client("/back-api/admin/product/pos/get-paging", {
      page: 1,
      limit: total + 1,
      organization_id: id,
    });
    await increaseStateInProgress();
    await productDb.connect().then((apis) => {
      apis.editProducts(data || []);
    });
    await increaseStateInProgress();
    const prevOrgDataInLocalStorage =
      JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_CONST.organization}_${id}`)
      ) || {};
    localStorage.setItem(
      `${LOCAL_STORAGE_CONST.organization}_${id}`,
      JSON.stringify({
        ...prevOrgDataInLocalStorage,
        [LOCAL_STORAGE_CONST.productUpdatedAt]: time,
        [LOCAL_STORAGE_CONST.productDeletedAt]: time,
      })
    );
  };

  const getAllCategoriesForDb = async (total) => {
    const { data, time } = await posUniversal.client(
      "/back-api/admin/product/category/get-paging",
      {
        page: 1,
        limit: total + 1,
        organization_id: id,
      }
    );
    await increaseStateInProgress();
    await categoryDb.connect().then((apis) => {
      apis.editCategories(data?.data || []);
    });
    await increaseStateInProgress();
    const prevOrgDataInLocalStorage =
      JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_CONST.organization}_${id}`)
      ) || {};
    localStorage.setItem(
      `${LOCAL_STORAGE_CONST.organization}_${id}`,
      JSON.stringify({
        ...prevOrgDataInLocalStorage,
        [LOCAL_STORAGE_CONST.categoryUpdatedAt]: time,
        [LOCAL_STORAGE_CONST.categoryDeletedAt]: time,
      })
    );
  };

  const getAllCustomerForDb = async (total) => {
    const { data, time } = await posUniversal.client(
      "/back-api/admin/sale/customer/get-for-transaction",
      { type: "physical", limit: total + 1, page: 1, organization_id: id }
    );
    await increaseStateInProgress();
    await customerDb
      .connect()
      .then((apis) => apis.editCustomers(data?.data || []));
    await increaseStateInProgress();
    const prevOrgDataInLocalStorage =
      JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_CONST.organization}_${id}`)
      ) || {};
    localStorage.setItem(
      `${LOCAL_STORAGE_CONST.organization}_${id}`,
      JSON.stringify({
        ...prevOrgDataInLocalStorage,
        [LOCAL_STORAGE_CONST.customerUpdatedAt]: time,
        [LOCAL_STORAGE_CONST.customerDeletedAt]: time,
      })
    );
  };

  const getAllSalesForDb = async (total) => {
    const { data, time } = await posUniversal.client(
      "/back-api/admin/pos/receipt/get-paging",
      {
        limit: total + 1,
        page: 1,
        organization_id: id,
      }
    );
    await increaseStateInProgress();
    await receiptDb
      .connect()
      .then((apis) => apis.editReceipts(data?.data || []));
    await increaseStateInProgress();
    const prevOrgDataInLocalStorage =
      JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_CONST.organization}_${id}`)
      ) || {};
    localStorage.setItem(
      `${LOCAL_STORAGE_CONST.organization}_${id}`,
      JSON.stringify({
        ...prevOrgDataInLocalStorage,
        [LOCAL_STORAGE_CONST.receiptUpdatedAt]: time,
        [LOCAL_STORAGE_CONST.receiptDeletedAt]: time,
      })
    );
  };

  const getAllPageForDb = async () => {
    const { data } = await posUniversal.client(
      "/back-api/admin/pos/page/get-all-with-items",
      { organization_id: id }
    );
    await increaseStateInProgress();
    await pageDb.connect().then((apis) => {
      apis.addPages(data || []);
    });
    data?.length &&
      (await data?.map((page) =>
        eachPageDataDb.connect().then((apis) => {
          apis.addEachPageData(page?.items || []);
        })
      ));
    await increaseStateInProgress();
  };

  const getProductsTotalForDb = async () => {
    const { data } = await posUniversal.client(
      "/back-api/admin/product/pos/get-paging",
      {
        page: 1,
        limit: 1,
        organization_id: id,
      }
    );
    await getAllProductsForDb(data?.total);
  };

  const getCategoriesTotal = async () => {
    const { data } = await posUniversal.client(
      "/back-api/admin/product/category/get-paging",
      {
        page: 1,
        limit: 1,
        organization_id: id,
      }
    );
    await getAllCategoriesForDb(data?.total);
  };

  const getCustomerTotal = async () => {
    const { data } = await posUniversal.client(
      "/back-api/admin/sale/customer/get-for-transaction",
      { type: "physical", limit: 1, page: 1, organization_id: id }
    );
    await getAllCustomerForDb(data?.total);
  };

  const getSalesTotal = async () => {
    const { data } = await posUniversal.client(
      "/back-api/admin/pos/receipt/get-paging",
      {
        limit: 1,
        page: 1,
        organization_id: id,
      }
    );
    await getAllSalesForDb(data?.total);
  };

  const setAllPosDataToDb = async () => {
    setProgress(0);
    await getProductsTotalForDb();
    await getCategoriesTotal();
    await getCustomerTotal();
    await getSalesTotal();
    await getAllPageForDb();
    const prevOrgDataInLocalStorage =
      JSON.parse(
        localStorage.getItem(`${LOCAL_STORAGE_CONST.organization}_${id}`)
      ) || {};
    localStorage.setItem(
      `${LOCAL_STORAGE_CONST.organization}_${id}`,
      JSON.stringify({
        ...prevOrgDataInLocalStorage,
        [LOCAL_STORAGE_CONST.status]: POS_DB_STATUS_VALUE.created,
      })
    );
  };

  return { setAllPosDataToDb, progress };
};

export default useSetAllPosDataToDb;
