import { Api } from "../api";

class HrApi extends Api {
  createEmployee(data) {
    return this.execute("post", "/back-api/admin/hr/employee/create", data);
  }

  updateEmployee(data) {
    return this.execute("post", "/back-api/admin/hr/employee/update", data);
  }

  getEmployeeById(data) {
    return this.execute("post", "/back-api/admin/hr/employee/get-by-id", data);
  }

  getEmployeePaging(data) {
    return this.execute("post", "/back-api/admin/hr/employee/get-paging", data);
  }

  deleteEmployeeById(data) {
    return this.execute("post", "/back-api/admin/hr/employee/delete", data);
  }

  deleteManyEmployee(data) {
    return this.execute(
      "post",
      "/back-api/admin/hr/employee/delete-many",
      data
    );
  }

  getEmployeeForChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/hr/employee/get-for-choose",
      data
    );
  }

  createRole(data) {
    return this.execute("post", "/back-api/admin/hr/role/create", data);
  }

  updateRole(data) {
    return this.execute("post", "/back-api/admin/hr/role/update", data);
  }

  getRoleById(data) {
    return this.execute("post", "/back-api/admin/hr/role/get-by-id", data);
  }

  getRolePaging(data) {
    return this.execute("post", "/back-api/admin/hr/role/get-paging", data);
  }

  deleteRoleMany(data) {
    return this.execute("post", "/back-api/admin/hr/role/delete-many", data);
  }

  getSectionForChoose(data) {
    return this.execute(
      "post",
      "back-api/admin/hr/section/get-for-choose",
      data
    );
  }

  getPositionForChoose(data) {
    return this.execute(
      "post",
      "/back-api/admin/hr/position/get-for-choose",
      data
    );
  }

  createSection(data) {
    return this.execute("post", "/back-api/admin/hr/section/create", data);
  }

  getSectionById(data) {
    return this.execute("post", "/back-api/admin/hr/section/get-by-id", data);
  }

  updateSection(data) {
    return this.execute("post", "/back-api/admin/hr/section/update", data);
  }

  deleteByIdSection(data) {
    return this.execute("post", "/back-api/admin/hr/section/delete", data);
  }

  deleteManySection(data) {
    return this.execute("post", "/back-api/admin/hr/section/delete-many", data);
  }

  getPagingSection(data) {
    return this.execute("post", "/back-api/admin/hr/section/get-paging", data);
  }

  deletePostionsMany(data) {
    return this.execute(
      "post",
      "/back-api/admin/hr/position/delete-many",
      data
    );
  }

  deleteSectionById(data) {
    return this.execute("post", "/back-api/admin/hr/section/delete", data);
  }

  chooseSectionParent(data) {
    return this.execute(
      "post",
      "/back-api/admin/hr/section/choose-parent",
      data
    );
  }
}

export default HrApi;
