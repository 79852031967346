import { useTranslation } from "react-i18next";

import useCustomToastNotification from "../customToast/useCustomToastNotification";

const useNotificationPos = () => {
  const { t } = useTranslation();
  const { notification } = useCustomToastNotification();

  const notificationProductLine = (productName, productId, productState) => {
    notification(
      productState === "red_line"
        ? t("NOTIFICATION.ON_THE_RED_LINE")
        : productState === "yellow_line"
        ? t("NOTIFICATION.ON_THE_YELLOW_LINE")
        : productState === "negative" && t("NOTIFICATION.DID_NOT_STAY"),
      productName,
      true,
      `/products/products/edit/${productId}`
    );
  };

  return { notificationProductLine };
};

export default useNotificationPos;
