import { Controller } from "react-hook-form";

import SimpleSelect from "../SimpleSelect/SimpleSelect";

const ControlledSimpleSelect = ({ name, control, rules, errors, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <SimpleSelect
          id={name}
          {...field}
          {...props}
          hookFormChangeHandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    ></Controller>
  );
};

export default ControlledSimpleSelect;
