import { Controller } from "react-hook-form";

import { Select } from "..";

const ControlledSelect = ({ name, control, rules, errors, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Select
          id={name}
          {...field}
          {...props}
          hookFormChangeHandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default ControlledSelect;
