import {toast} from "react-hot-toast"
import {update_task} from "../../store/tasks/update";
import {Success} from "../../api";



const update_Task_Reducer = (state = update_task, action) => {
    switch (action.type) {
        case "UPDATE_TASK_0" :
            return Success("/task/main", "updated successfully!!!");
        case "UPDATE_TASK_52000":
            toast.error(action.payload);
            break;
        case "UPDATE_TASK_10001":
            toast.error(action.payload);
            break;
        case "UPDATE_TASK_10000":
            toast.error(action.payload);
            break;
        default:
            return state
    }
}
export default update_Task_Reducer