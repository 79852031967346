import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

import Error from "../Error/Error";
import Label from "../Label/Label";
import { StyledSelect } from "./SimpleSelect.style";

const SimpleSelect = ({
  label,
  options = [],
  onChange,
  itemLabelKey,
  error,
  ...props
}) => {
  const { t } = useTranslation();

  const renderLabel = (label) => <Label>{label}</Label>;

  const changeHandler = (event) => {
    onChange(event);
  };

  const getOptionLabel = (option) => {
    return typeof props.getOptionLabel === "function"
      ? props.getOptionLabel(option)
      : option.label || t(option.translationKey);
  };

  useEffect(() => {
    if (props.hookFormChangeHandler && props.defaultValue) {
      props.hookFormChangeHandler(props.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  const renderSelect = () => (
    <StyledSelect onChange={changeHandler} error={error} {...props}>
      {options.map((option, index) => (
        <MenuItem value={option.value} key={index}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </StyledSelect>
  );

  const renderError = (error) => (
    <Error>{error.message || "Invalid field"}</Error>
  );
  return (
    <div>
      {label && renderLabel(label)}
      {renderSelect()}
      {error && renderError(error)}
    </div>
  );
};

export default SimpleSelect;
