import { Grid } from "@mui/material";

import { GridSkeletonStyle } from "./GridSkeleton.style";
import Card from "../../Card/Card";

const GridSkeleton = ({ color, fields, ...props }) => {
  return (
    <Card>
      <Grid container spacing={2}>
        {fields.map(({ grid, isShow = true }, index) => (
          <Grid item {...grid} key={index}>
            {isShow && (
              <>
                <GridSkeletonStyle variant="text" height={13} width={100} />
                <GridSkeletonStyle animation="wave" {...props} />
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default GridSkeleton;
