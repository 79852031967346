import { Controller } from "react-hook-form";

import InputMask from "../InputMask/InputMask";

const ControlledInputMask = ({
  name,
  control,
  transform = {
    input: (value) => value,
    output: (e) => {
      const output = e.target.value?.replaceAll(" ", "")?.replaceAll("-", "");
      return output;
    },
  },
  rules,
  onChange,
  errors,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      setValue={(v) => v?.replaceAll(" ", "")?.replaceAll("-", "")}
      render={({ field }) => (
        <InputMask
          id={name}
          {...field}
          {...props}
          onChange={(e) => {
            field.onChange(transform.output(e));
            onChange && onChange(transform.output(e));
          }}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default ControlledInputMask;
