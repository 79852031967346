import { useEffect } from "react";
import { Box, Checkbox as MuiCheckbox } from "@mui/material";
import get from "lodash.get";

import { StyledLabel } from "./Checkbox.style";

export default function Checkbox({ label, right = true, ...props }) {
  const renderLabel = () =>
    label && <StyledLabel htmlFor={get(props, "id")}>{label}</StyledLabel>;
  useEffect(() => {
    props.hookFormChangeHandler &&
      props.defaultChecked &&
      props.hookFormChangeHandler(props.defaultChecked);
  }, []);

  return (
    <Box display="flex" alignItems="center">
      {!right && renderLabel()}
      <MuiCheckbox {...props} />
      {right && renderLabel()}
    </Box>
  );
}
