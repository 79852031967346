import { Api } from "../api";

export default class CategoriesApi extends Api {
  getCategories(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/get-paging",
      data
    );
  }
  getCategoryById(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/get-by-id",
      data
    );
  }
  getCategoryItems(data) {
    return this.execute(
      "post",
      "/back-api/admin/product/category/get-paging ",
      data
    );
  }
}
