import { styled } from "@mui/system";
import { AppBar, Box } from "@mui/material";

export const StyledIconButtonBlock = styled("div")`
  & .sm {
    display: flex !important;
  }
  & .md {
    display: none !important;
  }
  @media (min-width: 769px) {
    & .sm {
      display: none !important;
    }
    & .md {
      display: flex !important;
    }
  }
`;

export const StyledAppBar = styled(AppBar)({
  boxShadow: "none",
});

export const StyledAlert = styled("div")({
  fontSize: "15px",
  fontWeight: 400,
  height: "43px",
  display: "flex",
  justifyContent: "center",
  letterSpacing: "0.01071em",
  "& .MuiTypography-root": {
    marginLeft: "12px",
  },
  backgroundColor: "#FFEA00",
  color: "#000",
  alignItems: "center",
  borderRadius: "12px",
});

export const StyledSwitchOfflineBox = styled(Box)({
  display: "flex",
  backgroundColor: "#fff",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  color: "#313649",
  padding: "0 10px",
  cursor: "pointer",
  userSelect: "none",
});
