export const POS_DB = "POS_DB";

export const POS_DB_COLLECTIONS = [
  {
    name: {
      singular: "Product",
      plural: "Products",
    },
    keyPath: "_id",
  },
  {
    name: {
      singular: "Page",
      plural: "Pages",
    },
    keyPath: "_id",
  },
  {
    name: {
      singular: "EachPageDatum",
      plural: "EachPageData",
    },
    keyPath: "_id",
  },
  {
    name: {
      singular: "SaleSetting",
      plural: "SaleSettings",
    },
    keyPath: "_id",
  },
  {
    name: {
      singular: "Category",
      plural: "Categories",
    },
    keyPath: "_id",
  },
  {
    name: {
      singular: "Customer",
      plural: "Customers",
    },
    keyPath: "_id",
  },
  {
    name: {
      singular: "Receipt",
      plural: "Receipts",
    },
    keyPath: "uuid",
    // deleters: ["sale"],
    // adders: ["sale"],
  },
];
