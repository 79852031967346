import { useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box, Fade, TextField } from "@mui/material";
import get from "lodash.get";
import useOutsideHandler from "hooks/useOutsideHandler";

import {
  StyledPopper,
  StyledSelect,
  StyledTimeRangePicker,
} from "./TimeRangePicker.style";
import { MODIFIERS } from "./TimeRangePicker.constants";
import { isEmptyArray } from "utils";

const TimeRangePicker = ({
  onChange,
  defaultValues = [],
  fromTimeName = "from_time",
  toTimeName = "to_time",
}) => {
  const [times, setTimes] = useState(defaultValues);
  const [isFullDayRange, setIsFullDayRange] = useState(
    isEmptyArray(defaultValues) ||
      (defaultValues[0] === "00" && defaultValues[1] === "00")
  );
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const outsideOfSelectRef = useRef();
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (isFullDayRange) {
      return "Butun kun";
    }
    return times.reduce((res, time = "00", index) => {
      return index === 0 ? `${time}:00` : `${res}-${time}:00`;
    }, "");
  }, [times, isFullDayRange]);

  const openAnchorElement = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  useOutsideHandler(outsideOfSelectRef, setIsOpen);

  const timeChangeHandler = (event, index) => {
    const { value } = event.target;
    times[index] = value;
    setTimes([...times]);
    changeHandler(times);
  };

  const fullDayClickHandler = () => {
    setIsFullDayRange(true);
    const fullDayTimes = [0, 0];
    setTimes(fullDayTimes);
    changeHandler(fullDayTimes);
  };
  const changeHandler = (times) => {
    onChange &&
      onChange([
        { name: fromTimeName, time: +times[0] },
        { name: toTimeName, time: +times[1] },
      ]);
  };

  const renderTimeStartSelect = (name, value, index) => (
    <div>
      <label>{t("COMMON.START")}</label>
      <br />
      <StyledSelect
        onChange={(e) => timeChangeHandler(e, index)}
        value={value}
        name={name}
      >
        {Array(10)
          .fill("")
          .map((_, index) => (
            <option
              disabled={+times[1] ? +times[1] <= +index : false}
              key={index}
              value={`0${index}`}
            >
              0{index}:00
            </option>
          ))}
        {Array(14)
          .fill("")
          .map((_, index) => (
            <option
              disabled={+times[1] ? +times[1] <= +index + 10 : false}
              key={index}
              value={index + 10}
            >
              {index + 10}:00
            </option>
          ))}
        {index === 1 && <option value={0}>23:59</option>}
      </StyledSelect>
    </div>
  );

  const renderTimeEndSelect = (name, value, index) => (
    <div>
      <label>{t("COMMON.END")}</label>
      <br />
      <StyledSelect
        onChange={(e) => timeChangeHandler(e, index)}
        value={value}
        name={name}
      >
        {Array(10)
          .fill("")
          .map((_, index) => (
            <option
              disabled={+times[0] ? +times[0] >= +index : false}
              key={index}
              value={`0${index}`}
            >
              0{index}:00
            </option>
          ))}
        {Array(14)
          .fill("")
          .map((_, index) => (
            <option
              disabled={+times[0] ? +times[0] >= +index + 10 : false}
              key={index}
              value={index + 10}
            >
              {index + 10}:00
            </option>
          ))}
        {index === 1 && <option value={0}>23:59</option>}
      </StyledSelect>
    </div>
  );

  return (
    <>
      <StyledTimeRangePicker>
        <img src={"/images/clock.svg"} alt={"clock"} />
        <TextField value={value} onClick={openAnchorElement} />
      </StyledTimeRangePicker>
      <StyledPopper
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        ref={outsideOfSelectRef}
        modifiers={MODIFIERS}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box className="hour_select_header">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label>
                    <input
                      type="radio"
                      checked={isFullDayRange}
                      onChange={fullDayClickHandler}
                    />{" "}
                    {t("COMMON.ALL_DATE")}
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <input
                      type="radio"
                      checked={!isFullDayRange}
                      onChange={() => setIsFullDayRange(false)}
                    />{" "}
                    {t("COMMON.CUSTOM_PERIOD")}
                  </label>
                </Grid>
              </Grid>
              {!isFullDayRange && (
                <div className="hour_select_body">
                  {renderTimeStartSelect(fromTimeName, times[0], 0)}
                  {renderTimeEndSelect(toTimeName, times[1], 1)}
                </div>
              )}
            </Box>
          </Fade>
        )}
      </StyledPopper>
    </>
  );
};

export default TimeRangePicker;
