import { Api } from "../api";

export default class MaterialApi extends Api {
  getMaterialIncomeAutoComplete(data) {
    return this.execute(
      "post",
      "//back-api/admin/warehouse/material/income-autocomplete",
      data
    );
  }
  getForChose(data) {
    return this.execute(
      "post",
      "//back-api/admin/warehouse/material/get-for-choose",
      data
    );
  }

  cancelMaterialTransfer(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/transfer/cancel",
      data
    );
  }

  getMaterialById(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/get-by-id",
      data
    );
  }

  createMaterial(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/create",
      data
    );
  }

  updateMaterial(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/update",
      data
    );
  }

  chooseCategoryParent(data) {
    return this.execute(
      "post",
      "/back-api/admin/warehouse/material/choose-parent",
      data
    );
  }
}
