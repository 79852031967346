import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import { StyleCardSizeWrapper, StyledCard } from "./LayoutMain.style";

function getWindow() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindow());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindow());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

const LayoutMain = ({ children, bg = "white", style }) => {
  const [open, setOpen] = useState(false);
  const [checkTogglePadding, setCheckTogglePadding] = useState(false);
  const { width } = useWindowDimensions();
  const checkToggle = useSelector((state) => state.external.check);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const { isExpired } = useJwt(token);
  useEffect(() => {
    if (!token || isExpired) {
      window.location.href = "/";
      localStorage.clear();
    }
  }, [location, isExpired, token]);

  useEffect(() => {
    // let styleProps = document.querySelector(".card-size-wrapper");
    if (width <= "576") {
      if (!checkToggle) {
        setCheckTogglePadding(true);
        //     styleProps.style.paddingLeft = "80px";
      }
    }
    setOpen(!open);
  }, [checkToggle]);
  return (
    <StyleCardSizeWrapper
      className={checkTogglePadding ? "checkTogglePadding" : ""}
    >
      <StyledCard
        style={
          location.pathname === "/task/user/board" ||
          location.pathname.includes("/pos/")
            ? {
                backgroundColor: "transparent",
                padding: 0,
                height:
                  !location.pathname.includes("/pos/choose-organization") &&
                  "100%",
              }
            : { background: bg }
        }
      >
        {children}
      </StyledCard>
    </StyleCardSizeWrapper>
  );
};

export default LayoutMain;
