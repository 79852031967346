import { Api } from "../api";

class settingsApi extends Api {
  createPaymentMethod(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/payment-method/create",
      data
    );
  }

  updatePaymentMethod(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/payment-method/update",
      data
    );
  }

  getPaymentMethod(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/payment-method/get-by-id",
      data
    );
  }

  getPaymentMethodAll(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/payment-method/get-all",
      data
    );
  }

  setReceiptDetails(data) {
    return this.execute("post", "/back-api/admin/settings/receipt/set", data);
  }

  getReceiptDetails() {
    return this.execute("post", "/back-api/admin/settings/receipt/get");
  }

  getReceiptDetailsById(data) {
    return this.execute("post", "/back-api/admin/settings/receipt/get", data);
  }

  createOrganization(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/organization/create",
      data
    );
  }

  updateOrganization(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/organization/update",
      data
    );
  }

  getAllOrganization() {
    return this.execute("post", "back-api/admin/settings/organization/get-all");
  }

  deleteOrganizationById(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/organization/delete",
      data
    );
  }

  createPosDevice(data) {
    return this.execute("post", "/back-api/admin/settings/device/create", data);
  }

  updatePosDevice(data) {
    return this.execute("post", "/back-api/admin/settings/device/update", data);
  }

  getPosDeviceById(data) {
    return this.execute(
      "post",
      "/back-api/admin/settings/device/get-by-id",
      data
    );
  }

  getAllCurrencies() {
    return this.execute("post", "/back-api/admin/finance/currency/get-all");
  }
}

export default settingsApi;
