import { styled } from "@mui/system";

export const StyledTimePicker = styled("div")(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 12,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23) !important",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#026aff !important",
  },
  "&.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EA5455",
  },
}));
