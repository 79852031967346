import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Error from "../Error/Error";
import Label from "../Label/Label";
import { StyledInput } from "./Input.style";

const Input = ({ label, inputType = "string", error, ...props }) => {
  const { t } = useTranslation();
  useEffect(() => {
    props.hookFormChangeHandler &&
      props.defaultValue &&
      props.hookFormChangeHandler(props.defaultValue);
  }, []);

  const renderLabel = () => <Label>{label}</Label>;

  const renderInput = () => (
    <StyledInput
      onWheel={(event) => {
        event.target.blur();
      }}
      error={error}
      type={inputType}
      {...props}
    />
  );

  const renderError = () => (
    <Error>{error.message || t("error_messages.invalid_value")}</Error>
  );

  return (
    <div>
      {label && renderLabel()}
      {renderInput()}
      {error && renderError(error)}
    </div>
  );
};

export default Input;
