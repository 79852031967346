import axios from "axios";
import error_message_handler from "services/error_message_handler";
import { baseUrl, head_token } from "../../../api";

export const delete_Payment_Many_Action = (id) => async (dispatch) => {
  try {
    await axios
      .post(
        `${baseUrl}/back-api/admin/settings/payment-method/delete-many`,
        id,
        {
          headers: head_token,
        }
      )
      .then((response) => {
        dispatch({
          type: "DELETE_PAYMENT_MANY_0",
          payload: response,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/login");
        } else {
          error_message_handler(error.response.data.code);
        }
        if (error.response) {
          const code = error.response.data.code;
          switch (code) {
            case 10001:
              dispatch({
                type: "DELETE_PAYMENT_MANY_10001",
                payload: "error 10001",
              });
              break;
            case 10000:
              dispatch({
                type: "DELETE_PAYMENT_MANY_10000",
                payload: "error 10000",
              });
              break;
            default:
              return error.response;
          }
        }
      });
  } catch (err) {}
};
