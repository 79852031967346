import {
  Autocomplete,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledMultiSelect } from "./MultiSelect.style";

const MultiSelect = ({
  onChange,
  onChangeLocal,
  labelTranslationKey,
  itemLabelKey,
  itemValueKey,
  label,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation();
  const changeHandler = (event, options) => {
    onChange && onChange(options, props.name, itemValueKey);
    onChangeLocal && onChangeLocal(options, props.name, itemValueKey);
  };
  const renderLabel = () => (
    <Typography id="demo-simple-select-label">
      {labelTranslationKey ? t(labelTranslationKey) : label}
    </Typography>
  );
  return (
    <StyledMultiSelect>
      <Autocomplete
        multiple
        limitTags={1}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField label={renderLabel()} {...params} />
        )}
        renderOption={(props, option, { selected }) => (
          <MenuItem
            {...props}
            value={option[itemValueKey]}
            key={option[itemValueKey]}
          >
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option[itemLabelKey]}
          </MenuItem>
        )}
        {...props}
        defaultValue={
          defaultValue?.length && props?.options?.length
            ? defaultValue.map((value) =>
                props.options.find((option) => option[itemValueKey] === value)
              )
            : undefined
        }
        getOptionLabel={(options) => options?.[itemLabelKey]}
        onChange={changeHandler}
      />
    </StyledMultiSelect>
  );
};

export default MultiSelect;
