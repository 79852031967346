import { styled } from "@mui/system";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)({
  display: "block",
  minWidth: "80px",
  "& .MuiOutlinedInput-root": {
    width: "100%",
    borderRadius: 12,
  },
  "& .error .MuiOutlinedInput-root": {
    borderColor: "red !important",
  },
});
