import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { StyledTimePicker } from "./TimePicker.style";
import { Label } from "..";
import Error from "../Error/Error";

const TimePicker = ({
  inputFormat = "dd/MM/yyyy, HH:mm",
  label,
  error,
  ...props
}) => {
  const renderLabel = (label) => <Label>{label}</Label>;

  const renderTimeInput = () => (
    <StyledTimePicker className={error && "error"}>
      <DateTimePicker
        inputFormat={inputFormat}
        renderInput={(params) => <TextField {...params} />}
        {...props}
      />
    </StyledTimePicker>
  );

  const renderError = (error) => (
    <Error>{error?.message || "Invalid field"}</Error>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {label && renderLabel(label)}
      {renderTimeInput()}
      {error && renderError(error)}
    </LocalizationProvider>
  );
};

export default TimePicker;
