import { useTranslation } from "react-i18next";
import { AddCircleOutline } from "@mui/icons-material";

import { StyledButton, StyledTypography } from "./ButtonWithIcon.style";

const ButtonWithIcon = ({ children, textColor = "#fff", icon, ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledButton
      startIcon={
        icon || <AddCircleOutline color={textColor} fontSize="small" />
      }
      size="large"
      color="primary"
      variant="contained"
      {...props}
    >
      <StyledTypography color={textColor}>
        {children || t("COMMON.CREATE")}
      </StyledTypography>
    </StyledButton>
  );
};

export default ButtonWithIcon;
