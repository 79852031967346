import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Fade, Typography, Button, Popper } from "@mui/material";

import { modifiers } from "./Confirmation.Constants";
import useOutsideHandler from "../../../hooks/useOutsideHandler";
import { StyledConfirmationBody } from "./Confirmation.styled";

const Confirmation = (props) => {
  const { t } = useTranslation();
  const outsideOfSelectRef = useRef();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    children,
    onConfirm,
    title = t("COMMON.DO_YOU_WANT_TO_DELETE"),
    style = {},
  } = props;
  useOutsideHandler(outsideOfSelectRef, setOpen);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;
  return (
    <>
      <span onClick={handleClick} style={style}>
        {children}
      </span>
      <Popper
        style={{ zIndex: 9998, backgroundColor: "transparent" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        ref={outsideOfSelectRef}
        transition
        modifiers={modifiers}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <StyledConfirmationBody>
              <Typography variant="subtitle1">{title}</Typography>
              <Box sx={{ marginTop: "20px", textAlign: "end" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  Yoq
                </Button>
                &nbsp; &nbsp;
                <Button
                  size="small"
                  variant="contained"
                  onClick={onConfirm}
                  color="error"
                >
                  Ha
                </Button>
              </Box>
            </StyledConfirmationBody>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default Confirmation;
