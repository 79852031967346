import { useEffect, useState } from "react";

const useNetwork = () => {
  const [network, setNetwork] = useState({ online: navigator.onLine });

  useEffect(() => {
    const offlineCallBack = () => {
      setNetwork((prev) => ({ ...prev, online: false }));
    };
    const onlineCallBack = () => {
      setNetwork((prev) => ({ ...prev, online: true }));
    };

    window.addEventListener("online", onlineCallBack);
    window.addEventListener("offline", offlineCallBack);

    return () => {
      window.removeEventListener("online", onlineCallBack);
      window.removeEventListener("offline", offlineCallBack);
    };
  }, []);
  return network;
};

export default useNetwork;
