export const TABLE_NAMES = {
  CURRENCIES: "currencies",
  ORGANIZATIONS: "organizations",
  USERS: "users",
  ROLES: "roles",
  PAYMENT_METHODS: "paymentMethods",
  SALES: "sales",
  PRODUCTS: "products",
  PRODUCT_CATEGORIES: "productCategories",
  CUSTOMERS: "customers",
  SALE_SETTINGS: "saleSettings",
};
