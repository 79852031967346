import {toast} from "react-hot-toast"

import { sale_contract_template_store } from "redux/store/sale/contract/template/saleContractTemplateStore";

const get_Sale_Contract_Template_Is_Main_Reducer = (state = sale_contract_template_store, action) => {
    switch (action.type) {
        case "GET_BY_ID_SALE_CONTRACT_TEMPLATE_IS_MAIN_0" :
            return {data: action.payload};
        case "GET_BY_ID_SALE_CONTRACT_TEMPLATE_IS_MAIN_10001":
            return toast.error(action.payload);
        case "GET_BY_ID_SALE_CONTRACT_TEMPLATE_IS_MAIN_10000":
            return toast.error(action.payload);
        default:
            return state
    }
}
export default get_Sale_Contract_Template_Is_Main_Reducer