import { useState } from "react";

export default function useDebounce() {
  const [debouncedValue, setDebouncedValue] = useState("");

  const debounce = () => {
    let tomeOut;
    return (text, ms = 1000) => {
      clearTimeout(tomeOut);
      tomeOut = setTimeout(() => setDebouncedValue(text), ms);
    };
  };

  return [debouncedValue, debounce()];
}
