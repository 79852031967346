export function generateAdditionalApis(collection, rowId, collectionConfig) {
  const { deleters = [], adders = [] } = collectionConfig;

  const deleteItem = async (id, field) => {
    const rowData = await collection.getById(rowId);
    const filteredSales = rowData[field].filter((sale) => sale._id !== id);
    rowData[field] = filteredSales;
    return collection.edit(rowData);
  };

  const addItem = async (item, field) => {
    const rowData = await collection.getById(rowId);
    rowData[field].push(item);
    return collection.edit(rowData);
  };

  const deleteApis = deleters.reduce((apis, field) => {
    return {
      ...apis,
      [`delete${toFirstletterUppercase(field)}`]: async (id) =>
        deleteItem(id, field),
    };
  }, {});

  const addApis = adders.reduce((apis, field) => {
    return {
      ...apis,
      [`add${toFirstletterUppercase(field)}`]: async (item) =>
        addItem(item, field),
    };
  }, {});

  return { ...deleteApis, ...addApis };
}

export function generateRestApis(collection, name) {
  return {
    [`get${name.singular}ById`]: async (id) => collection.getById(id),
    [`get${name.plural}`]: async () => collection.getAll(),
    [`add${name.singular}`]: async (item) => collection.add(item),
    [`add${name.plural}`]: async (items) => collection.addAll(items),
    [`edit${name.singular}`]: async (item) => collection.edit(item),
    [`edit${name.plural}`]: async (items) => collection.editAll(items),
    [`clear${name.plural}`]: async () => collection.clear(),
    [`delete${name.singular}`]: async (id) => collection.delete(id),
    [`delete${name.plural}`]: async (ids) => collection.deleteAll(ids),
  };
}

export function generateDBCollections(collections) {
  return collections.reduce(
    (res, collection) => [
      ...res,
      { name: collection.name.plural, keyPath: collection.keyPath },
    ],
    []
  );
}

export function toFirstletterUppercase(str) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}
