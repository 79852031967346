import { useContext, useEffect, useMemo } from "react";
import { Grid, IconButton, TableCell } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

import Table from "../../Table/Table";
import ListTableContext from "../context/ListTableContext";

const ListTable = ({
  payload,
  renderItemProps,
  data = [],
  deleteIcon = true,
  ...props
}) => {
  const {
    state: { list },
    actions: { deleteRowItem, tableCellChangeHandler, setList },
  } = useContext(ListTableContext);

  const columns = useMemo(
    () => [
      ...props.columns,
      deleteIcon && {
        renderComponent: (data) => (
          <TableCell>
            <IconButton>
              <DeleteOutline onClick={() => deleteRowItem(data)} />
            </IconButton>
          </TableCell>
        ),
        style: { width: "10px" },
      },
    ],
    [deleteRowItem, props.columns]
  );

  useEffect(() => {
    setList(data);
  }, []);

  const renderTable = useMemo(
    () => (
      <Table
        columns={columns}
        data={list}
        renderItemProps={{
          ...renderItemProps,
          onChange: tableCellChangeHandler,
        }}
      />
    ),
    [list, tableCellChangeHandler, renderItemProps, columns]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderTable}
      </Grid>
    </Grid>
  );
};

export default ListTable;
