import { Controller } from "react-hook-form";

import Checkbox from "../Checkbox/Checkbox";

const ControlledCheckbox = ({ control, name, rules, errors, ...props }) => {
  const inputChangeHandler = (e, formChangeHandler) => {
    formChangeHandler(e);
    const { onChange } = props;
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Checkbox
          id={name}
          {...props}
          {...field}
          onChange={(e) => inputChangeHandler(e, field.onChange)}
          hookFormChangeHandler={field.onChange}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default ControlledCheckbox;
