import { styled } from "@mui/system";

export const StyledCardSizeWrapper = styled("div")`
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  @media (min-width: 768px) {
    padding: 10px;
  }
`;

export const StyledCardSizeInner = styled("div")`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 110px);
  padding: 10px;
  color: #000;
  @media only screen and (max-width: 1250px) {
    .create-card {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  }
`;
export const StyledCreateCardDiv = styled("div")`
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  min-width: 280px;
  max-width: 1060px;
`;
