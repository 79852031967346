import ListTableContext from "./ListTableContext";
import { useListTable } from "./useListTable";

export default function ListTableProvider({ children }) {
  const store = useListTable();

  return (
    <ListTableContext.Provider value={store}>
      {children}
    </ListTableContext.Provider>
  );
}
