import { Api } from "../api";

class TaskAttachment extends Api {
  deleteFile(data) {
    return this.execute(
      "post",
      "/back-api/admin/tasks/attachment/remove",
      data
    );
  }

  getAllFile(data) {
    return this.execute(
      "post",
      "/back-api/admin/tasks/attachment/get-all",
      data
    );
  }

  addAllFile(data) {
    return this.execute("post", "/back-api/admin/tasks/attachment/add", data);
  }
}

export default TaskAttachment;
